import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6905792e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["maxlength", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      ref: "inputRef",
      maxlength: _ctx.maxlength,
      type: "text",
      size: "",
      style: _normalizeStyle({width:_ctx.state.width+2+'px'}),
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args)))
    }, null, 44, _hoisted_1),
    _createElementVNode("span", { ref: "spanRef" }, _toDisplayString(_ctx.modelValue), 513)
  ]))
}