import { createApp, provide, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { HttpLink, split, from } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
const wsLink = new WebSocketLink({
  // uri: `ws://localhost:8081/subscriptions`,
  uri: `wss://api.papth.jp/subscriptions`,
  options: {
    reconnect: true
  }
});
const httpLink = createHttpLink({
  // uri: 'http://localhost:8081/graphql',
  uri: 'https://api.papth.jp/graphql',
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
// 更新データをマージする際にキャッシュする設定
const cache = new InMemoryCache(
  {
    addTypename: false
  }
  // {
  //   typePolicies: {
  //     Query: {
  //       fields: {
  //         words: {
  //           merge(existing, incoming) {
  //             return incoming;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
)
const apolloClient = new ApolloClient({
  link: from([authLink,link]),
  cache,
})

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
}).use(store).use(router).mount('#app')
