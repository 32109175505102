<style lang="scss" scoped>
.content {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

</style>
<template>
	<div class="room">
		<div class="head-wrap"><h2>工事中</h2></div>
    <div class="content">
      <v-stage></v-stage>
    </div>
	</div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import VStage from '@/components/VStage.vue'

export default defineComponent({
	components: {
		VStage
	},
	setup(){
		const store = useStore();
		const user = computed(()=>{
			return store.getters["auth/getUser"];
		})
		return {
			user,
		}
	}
});
</script>