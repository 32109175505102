
import { useAddWordCategoryMutation, useAddWordMutation } from "@/graphql/graphql";
import { defineComponent, reactive, computed, ref, nextTick, onMounted } from "vue";
import { useStore } from "vuex";

import Modal from '@/components/Modal.vue'
interface State {
  words: Word[];
	currentInputIndex: number;
  currentEditWord: any;
	saveFlag: boolean;
  currentWordRef: any;
  showModal: boolean;
  category: string;
}
interface Word {
  id: number;
  word: string;
  categories: any[];
  active: boolean;
  edit: boolean;
  error: Error;
}
interface Error {
  dep: boolean;
  cat: boolean;
}
export default defineComponent({
  components: {
    Modal
  },
	props: {
    allCategories: Object,
	},
  setup(props) {
		onMounted(() => {
      state.currentEditWord = state.words[0];
      editor = editorWrap.value.children[0];
    });
    const scroll = ref();
    const editorWrap = ref();
    let editor:any = null;
		const store = useStore();
    const state = reactive<State>({
      words: [
        {
          id: 0,
          word: "",
          categories: [],
          edit: false,
          active: true,
          error: {
            dep: false,
            cat: false,
          },
        },
      ],
			currentInputIndex: 0,
			currentEditWord: {},
			saveFlag: false,
      currentWordRef: null,
      showModal: false,
      category: ""
    });
    const refreshWords = computed(() => {
      let copy = state.words.slice();
      copy.forEach((el, i) => {
        el.id = i;
      });
      return copy;
    });
    const mouseWheel = (e: any) => {
      scroll.value.scrollLeft -= (80 * e.wheelDelta) / 100;
    };
    const addInput = async (e: any) => {
      if (e.key == "Enter") {
        e.preventDefault();
        // 現在選ばれている入力欄が空でないなら
        if (state.words[state.currentInputIndex].word.length != 0) {
          // 現在選ばれている入力欄の次に入力欄を追加
          if (state.words[state.words.length - 1].word != "") {
            state.words.push({
              id: state.words.length,
              word: "",
              categories: [],
              edit: false,
              active: true,
              error: {
                dep: false,
                cat: false,
              },
            });
          }
          await nextTick();
          editor.children[state.words.length - 1].children[0].focus();
        }
      }
      if (e.key == "Backspace") {
        // 単語の登録が残り１個なら
        if (state.words.length == 1) {
          // 文字数が0なら
          if (state.currentEditWord.word.length == 0) {
            e.preventDefault();
            e.target.innerHTML = "<br>";
          }
        }
        // 単語の登録が1個以上あって
        else {
          // 対象の文字数が0なら
          if (
            state.words[state.currentInputIndex].word.length == 0 ||
            /\r\s|\n/.test(state.words[state.currentInputIndex].word)
          ) {
            e.preventDefault();
            state.words.splice(state.currentInputIndex, 1);
            await nextTick();
            if (state.currentInputIndex - 1 != -1) {
              editor.children[
                state.currentInputIndex - 1
              ].children[0].focus();
              await nextTick();
              let sel: any = window.getSelection();
              let range = sel.getRangeAt(0);
              const num = range.startContainer.nodeValue.length;
              range.setStart(range.startContainer, num);
              range.setEnd(range.startContainer, num);
              sel.removeAllRanges();
              sel.addRange(range);
            } else {
              editor.children[0].children[0].focus();
            }
          }
        }
      }

      if (e.key == "ArrowRight") {
        let sel: any = window.getSelection();
        let target =
          editor.children[state.currentInputIndex + 1].children[0];
        if (sel.anchorOffset == sel.anchorNode.length && target) {
          e.preventDefault();
          let range = sel.getRangeAt(0);
          let textNode = target.childNodes[0];
          range.setStart(textNode, 0);
          range.setEnd(textNode, 0);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      } else if (e.key == "ArrowLeft") {
        let sel: any = window.getSelection();
        let target =
          editor.children[state.currentInputIndex - 1].children[0];
        if (sel.anchorOffset == 0 && target) {
          e.preventDefault();
          let range = sel.getRangeAt(0);
          let textNode = target.childNodes[0];
          range.setStart(textNode, textNode.length);
          range.setEnd(textNode, textNode.length);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    };
    const paste = async (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      let clipboardData = e.clipboardData;
      let pastedData: string[] = clipboardData.getData("Text").split(/\r\n|\n/);
      let pastes: any[] = [];
      if (pastedData) {
        pastedData.forEach((el: string, i: number) => {
          pastes.push({
            id: state.currentInputIndex + i,
            word: el,
            categories: [],
            edit: false,
            error: {
              dep: false,
              cat: false,
            },
          });
        });
        state.words.splice(state.currentInputIndex, 0, ...pastes);
        await nextTick();
        pastes.forEach((el) => {
          editor.children[el.id].children[0].prepend(el.word);
        });
        state.currentInputIndex += pastes.length;
      }
    };
    const addWord = async () => {
      await nextTick();
      editor.children[
        editor.children.length - 1
      ].children[0].focus();
    };
    const words = computed(()=>{
      return store.getters["db/getWords"];
    })  
		// 単語の登録
    const wordMutation = useAddWordMutation({});
    const wordMutationLoading = wordMutation.loading;
    // チェック
    const check = () => {
      // 一つも入力されていない場合
      if(state.words[0].word.replace(/\s+/g,"").length==0){
        return false;
      }
      // 現在入力中のワードリスト
      const copy = state.words.slice();
      const check = copy.map((x) => x.word);
      // 登録済みのワードリスト
      const check2 = words.value.map((x:any) => x.word);
      const errors = check.filter(function (x, i, self) {
        if (self.indexOf(x) !== self.lastIndexOf(x) || check2?.includes(x)) {
          copy[i].error.dep = true;
          return true;
        }
        copy[i].error.dep = false;
        return false;
      });

      // カテゴリ未登録の確認
      const categoryError = copy.filter((x) => {
        // 入力されていてカテゴリが未設定なら
        let cat =
          x.categories.length == 0 && x.word.replace(/\s+/g, "").length != 0;
        x.error.cat = cat;
        return cat;
      });

      // エラーがなければtrue
      return errors.length == 0 && categoryError.length == 0;
    };
    const saveWords = async () => {
      if(!wordMutation.loading.value){
        state.saveFlag = check();
        if (state.saveFlag) {
          let words = refreshWords.value.map((x) =>
            Object({
              word: x.word.replace(/\r\n|\n|\s/, ""),
              categories: x.categories.map((str: string) => parseInt(str, 10)),
            })
          );
          words = words.filter((x) => x.word != "");
          try {
            await wordMutation.mutate({ words });
            state.words = [{
              id: 0,
              word: "",
              categories: [],
              edit: false,
              active: true,
              error: {
                dep: false,
                cat: false,
              },
            }],
            store.dispatch('toast/setNotice',{
              class: "green",
              message: "登録完了！"
            })
          } catch ({ message }) {
            if (/unique/.test(message as string)) {
              store.dispatch("toast/setNotice", {
                class: "red",
                message: message,
              });
            }
          }
        } else {
          store.dispatch('toast/setNotice',{
            class: "red",
            message: "カテゴリが未設定か、既に登録済みのワードがあります。"
          })
        }
      }
    };
    const pickAdd = (categoryIndex: string) => {
      if (state.currentEditWord.categories) {
        return state.currentEditWord.categories.some((e: any) => {
          return e == categoryIndex;
        });
      }
      return false;
    }
    // 新規ワードにカテゴリを追加する
    const toggleCategory = (categoryIndex: string) => {
      state.currentWordRef.focus();
      if (state.currentEditWord.word.length > 0) {
        const num = state.currentEditWord.categories.indexOf(categoryIndex);
        if (num == -1) {
          state.currentEditWord.categories.push(categoryIndex);
        } else {
          state.currentEditWord.categories.splice(num, 1);
        }
      }
      state.saveFlag = check();
    }
    const focusWord =()=>{
      state.currentWordRef = document.activeElement;
    }
    const focus = () => {
      editor.children[editor.children.length - 1].children[0].focus()
    }
    //

    const addWordCategoryMutation = useAddWordCategoryMutation({});
    const addCategory = () => {
      addWordCategoryMutation.mutate({
        wordCategoryNameInput: {
          name: state.category
        }
      })
    }
    addWordCategoryMutation.onDone((res)=>{
      state.showModal = false
    });
    const trim = (word:string) => {
      return word.replace(/\s+/g,'');
    }
    return {
      state,
      refreshWords,
      editorWrap,
      scroll,
      wordMutationLoading,
      addCategory,
      focus,
      mouseWheel,
			addInput,
			paste,
			addWord,
			check,
			saveWords,
      pickAdd,
      toggleCategory,
      focusWord,
      trim
    };
  },
});
