
import { defineComponent, reactive } from 'vue'
import Toast from '@/components/Toast.vue'

interface State {
  height: number;
}
export default defineComponent({
  components: {
    Toast
  },
  setup() {
    const state = reactive<State>({
      height: 0
    })

    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);
    // const app = document.getElementById('app');
    // const changeHeight = (e:any) => {
    //   if(app) app.style.height = e.target.height+"px"
    // }
    // window.visualViewport.addEventListener('resize',changeHeight);
    // window.visualViewport.addEventListener('scroll',changeHeight);
  }
})
