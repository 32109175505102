
import { defineComponent, computed, ref, reactive, nextTick } from "vue";
import { useStore } from "vuex";
import sanitizeHtml from 'sanitize-html';
interface State {
  isUpdate: boolean;
  prevRange: any;
  currentChoiseIndex: number;
  style: any;
  test: string;
  resText: string;
  searchCats: number[];
  templates: any;
}

export default defineComponent({
  setup() {
    const store = useStore();
    const state = reactive<State>({
      isUpdate: false,
      prevRange: {},
      currentChoiseIndex: 0,
      style: {},
      test: "",
      resText: "",
      searchCats: [],
      templates: [
        `私の長所は@アイテム1性の高さです。以前、@場所1へ1年留学をしていた事があるのですが、最初の1週間は様々な国の学生がいたので大変戸惑い、クラブ活動にも入ることができませんでした。\n@アイテム2を持って一人の学生に話しかけ、自分の中で恐怖心を取り払い、結果的に様々な国の@修飾1友達を作ることができました。\nまた、クラブ活動では@アイテム3部に入り、クリスマスでは地域のミサに参加し、@アイテム3を披露しました。帰国後も@アイテム4講師や、@アイテム5店でアルバイトをしましたが、人間関係を構築するのが早いと感じています。\nそのため、@アイテム1性の高さを活かし、御社で様々なお客様とコミニュケーションを取りながら、御社の社員として恥じぬような営業を行いたいと思っております。`,
        `今日午前10時15分ごろ、首都高速湾岸線の上り線で、@アイテム1が前方を走っていた@アイテム2と接触し、いずれも横転しました。路面に@アイテム1の積み荷の@アイテム3が散乱し、@人物1は撤去作業を行っており、現在も通行止めになっています。\nこの事故に巻き込まれた数名が軽傷を負ったものの、@状態1はないということです。警察では@アイテム1の@アイテム2が原因であるとみて捜査をしています。`,
        `今日午後7時15分ごろ、@すること1を終えて帰宅途中の女子@職業1に対し、不審な男が@修飾1@アイテム1を露出し見せつけてくる事案が発生しました。\nたまたま近くを巡回していた@職業2が女性の悲鳴を聞いて現場に駆けつけ男と対峙。\n男は抵抗し暴れたものの@職業2の@修飾2@技1によって抑え込まれ、現行犯で逮捕されました。\n取り調べでは「どうしても@修飾1@アイテム1を見せつけたかった。欲求が抑えられなかった」と供述し、犯行を認めているとのことです。`
      ]
    })
    const textarea = ref();
    const words = computed(()=>{
      return store.getters["db/getWords"];
    })
    const categories = computed(()=>{
      return store.getters["db/getCategories"];
    }) 

    const user = computed(()=>{
			return store.getters["auth/getUser"];
		})
    const choice = async (text: string) => {
      await nextTick();
      const sel = document.getSelection();
      if(sel && typeof state.prevRange.startContainer != "undefined" ){
        const range = document.createRange();
        range.setStart(state.prevRange.startContainer, state.prevRange.startOffset);
        range.setEnd(state.prevRange.startContainer, state.prevRange.startOffset);
        const reg = new RegExp("@" + text, "g");
        const match:any = textarea.value.textContent?.match(reg);
        const num = match ? match.length + 1 : 1;
        const textNode = text=="自分の名前"? "@自分の名前" : "@"+ text + num;
        const catText = document.createTextNode(textNode);
        range.insertNode(catText);
        range.setStart(catText, catText.length);
        range.setEnd(catText, catText.length);
        sel.removeAllRanges();
        sel.addRange(range);
        state.test = textarea.value.textContent;
      }
    };
    const input = (e:any) => {
      state.test = e.target.innerText;
    }

    const mouseWheel = (e: any) => {
      e.currentTarget.scrollLeft -= (80 * e.wheelDelta) / 100;
    }

    const change = () => {
      let temp:any = [];
      let text = state.test.replace(/@([^0-9|@]*?)([0-9]+)/g, function (t:string,p1:string,p2:number){
        const cat = categories.value.find((x:any)=>x.name==p1);
        if( typeof temp[cat.id] != "undefined" && typeof temp[cat.id][p2] != "undefined") {
          return '<span>'+ temp[cat.id][p2] +'</span>';
        }
        const filterWords = words.value.filter((word:any) => {
          let check = false;
          for(const cat of word.categories) {
            if(cat.name == p1) {
              check = true;
              break;
            }
          }
          return check;
        });
        const rand = Math.floor(Math.random() * (filterWords.length-1));
        let res = filterWords[rand];
        if(cat.name=="修飾" && res.mods.length){
          res = res.word+res.mods[Math.floor(Math.random() * res.mods.length)].mod;
        }else{
          res = res.word
        }
        temp[cat.id] = Object.assign({},temp[cat.id],{
          [p2]: res
        });
        return '<span>'+ res +'</span>'
      });
      text = text.replace(/@自分の名前/g,'<span>'+user.value.nickname+'</span>');
      state.resText = sanitizeHtml(text);
    }

    const updateCaretPos = () => {
      const sel = document.getSelection();
      if(sel) state.prevRange = sel.getRangeAt(0);
    }
    const changeText = (num:number) => {
      textarea.value.innerHTML = state.templates[num];
      state.test = state.templates[num];
    }
    return {
      state,
      store,
      categories,
      textarea,
      input,
      change,
      mouseWheel,
      choice,
      updateCaretPos,
      changeText
    }
  }
});
