<style lang="scss" scoped>
.dashboard {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .content {
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
  }
}
</style>
<template>
  <div class="dashboard">
    <div class="head-wrap"><h2>ダッシュボード</h2></div>
    <div class="content">
      <p v-if="user" class="margin-bottom20">今日のログインボーナスです。<br>{{user.nickname}}は<span class="point">{{ user.items[user.items.length-1].item }}</span>を手に入れた！</p>

      <h3 class="h3-main">説明</h3>
      <p class="margin-bottom20">ボドゲ用の単語とお題の編集ページ。<br>ログイン後30日間閲覧可能。404ページに繋がったらもう一回discordでログインリンクを発行して下さい。<br>スマホからも使えます。</p>

      <dl class="dl-list-1">
        <dt>2022-05-13</dt>
        <dd>単語100個くらい追加。お題も少し追加。単語の絞り込み時に個数を表示するようにした。<br>Botの不具合を修正して指差し確認した。</dd>
        <dt>2022-04-27</dt>
        <dd>助詞が[object,object]になっていたのを直した</dd>
        <dt>2022-04-26</dt>
        <dd>新しく見つけた経路探索のバグが倒せない<br>テストで修飾以外の単語でも助詞が付与されるバグの修正<br>ニュース2を追加</dd>
        <dt>2022-04-19</dt>
        <dd>壁の一部移動にアニメーション付与</dd>
        <dt>2022-04-17</dt>
        <dd>経路探索のバグ修正</dd>
        <dt>2022-04-16</dt>
        <dd>部屋に簡単なゲームを作ろうと思ったが全然簡単じゃなかった。</dd>
        <dt>2022-04-06</dt>
        <dd>テストの修飾カテゴリで助詞が付与されるように更新</dd>
        <dt>2022-03-24</dt>
        <dd>ログボの処理でエラーでてて入れなかったバグを修正。</dd>
        <dt>2022-03-23</dt>
        <dd>ボットを新しいデータベース用に改装。</dd>
        <dt>2022-03-22</dt>
        <dd>ログボとゲーム後の優勝賞品とで区別するためにアイテムに区分を設けた。</dd>
        <dt>2022-03-19</dt>
        <dd>単語の編集修正。現場猫確認済み。</dd>
        <dt>2022-03-19</dt>
        <dd>現在調整中のため単語の編集不可。</dd>
        <dt>2022-03-17</dt>
        <dd>入力した文章にランダムで単語を突っ込める"お試し"ページを追加。</dd>
        <dt>2022-03-15</dt>
        <dd>お題に自分のと有効無効の絞り込みボタン追加。<br>単語カテゴリ付けする前に目的の単語を探すが大変だったので検索機能追加。</dd>
        <dt>2022-03-14</dt>
        <dd>試験公開</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const store = useStore();
    const user = computed(()=>{
			return store.getters["auth/getUser"];
		})
    return {
      user
    }
  }
});
</script>