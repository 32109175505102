
import { defineComponent, nextTick, onActivated, onMounted, onRenderTracked, onRenderTriggered, reactive, ref, watch } from 'vue'

interface State {
  width: number;
}
export default defineComponent({
  props: {
    modelValue: String,
    maxlength: Number
  },
  setup(props,{emit}:{emit:any}) {
    const spanRef = ref();
    const inputRef = ref();
    const state = reactive<State>({
      width: 0
    })
    onMounted(()=>{
      // inputRef.value.focus()
      state.width = spanRef.value.getBoundingClientRect().width;
    })
    watch(()=>props.modelValue,async ()=>{
      await nextTick();
      state.width = spanRef.value.getBoundingClientRect().width;
    });
    return {
      spanRef,
      inputRef,
      state,
      input: (e:any) => {
        emit('update:modelValue', e.target.value)
      }
    }
  },
})
