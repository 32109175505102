import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-139c6227"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input" }
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = ["x", "y", "fill-opacity", "onClick"]
const _hoisted_4 = ["x1", "y1", "x2", "y2", "onClick"]
const _hoisted_5 = ["x1", "y1", "x2", "y2", "onClick"]
const _hoisted_6 = ["transform"]
const _hoisted_7 = ["cx", "cy"]
const _hoisted_8 = ["x", "y"]
const _hoisted_9 = ["x", "y"]
const _hoisted_10 = ["x", "y"]
const _hoisted_11 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1",
      class: "stage",
      width: _ctx.state.viewBox.width+11,
      height: _ctx.state.viewBox.height+11
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.stage, (row, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (panel, l) => {
            return (_openBlock(), _createElementBlock("rect", {
              key: panel.id,
              x: 10+50*l,
              y: 10+50*i,
              fill: "#666",
              "fill-opacity": _ctx.state.player.move && _ctx.state.moveable.includes(panel.id)?0.4:0,
              width: "50",
              height: "50",
              "stroke-width": "1",
              stroke: "rgba(255,255,255,0.03)",
              onClick: ($event: any) => (_ctx.clickPanel($event,panel.id))
            }, null, 8, _hoisted_3))
          }), 128))
        ], 64))
      }), 128)),
      (_ctx.state.player.shift)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.state.viewLines, (line) => {
            return (_openBlock(), _createElementBlock("line", {
              key: line.id,
              x1: _ctx.state.base*line.p1.x+_ctx.state.offset,
              y1: _ctx.state.base*line.p1.y+_ctx.state.offset,
              x2: _ctx.state.base*line.p2.x+_ctx.state.offset,
              y2: _ctx.state.base*line.p2.y+_ctx.state.offset,
              onClick: ($event: any) => (_ctx.clickLine(line)),
              class: "view-line"
            }, null, 8, _hoisted_4))
          }), 128))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.lines, (line) => {
        return (_openBlock(), _createElementBlock("line", {
          class: _normalizeClass(["line", {grab: _ctx.state.grabbable.find(x=>x==line.id) && _ctx.state.grabLine==null}]),
          key: line.id,
          x1: _ctx.state.base*line.p1.x+_ctx.state.offset,
          y1: _ctx.state.base*line.p1.y+_ctx.state.offset,
          x2: _ctx.state.base*line.p2.x+_ctx.state.offset,
          y2: _ctx.state.base*line.p2.y+_ctx.state.offset,
          onClick: ($event: any) => (_ctx.choiceLine(line))
        }, null, 10, _hoisted_5))
      }), 128)),
      _createElementVNode("g", {
        transform: `rotate(${90*_ctx.state.player.vec} ${_ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.x+_ctx.state.offset} ${_ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.y+_ctx.state.offset})`
      }, [
        _createElementVNode("circle", {
          fill: "#fff",
          cx: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.x+_ctx.state.offset,
          cy: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.y+_ctx.state.offset,
          r: "15"
        }, null, 8, _hoisted_7),
        _createElementVNode("rect", {
          width: "2",
          height: "6",
          rx: "5",
          ry: "5",
          x: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.x+_ctx.state.offset-6,
          y: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.y+_ctx.state.offset+3,
          fill: "#000"
        }, null, 8, _hoisted_8),
        _createElementVNode("rect", {
          width: "2",
          height: "6",
          rx: "5",
          ry: "0",
          x: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.x+_ctx.state.offset+4,
          y: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.y+_ctx.state.offset+3,
          fill: "#000"
        }, null, 8, _hoisted_9),
        _withDirectives(_createElementVNode("g", null, [
          _createElementVNode("rect", {
            width: "10",
            height: "10",
            rx: "4",
            ry: "4",
            x: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.x+_ctx.state.offset+10,
            y: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.y+_ctx.state.offset+15,
            fill: "#FFF"
          }, null, 8, _hoisted_10),
          _createElementVNode("rect", {
            width: "10",
            height: "10",
            rx: "4",
            ry: "4",
            x: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.x+_ctx.state.offset-20,
            y: _ctx.state.base/2+_ctx.state.base*_ctx.state.player.pos.y+_ctx.state.offset+15,
            fill: "#FFF"
          }, null, 8, _hoisted_11)
        ], 512), [
          [_vShow, _ctx.state.grabLine!=null]
        ])
      ], 8, _hoisted_6)
    ], 8, _hoisted_2)),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.move && _ctx.move(...args)))
    }, "移動" + _toDisplayString(_ctx.state.player.move), 1),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.grab && _ctx.grab(...args)))
    }, "掴む" + _toDisplayString(_ctx.state.player.shift), 1)
  ]))
}