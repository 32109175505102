
import {
  useThemesQuery,
  useAddNormalThemesMutation,
  useUpdateNormalThemesMutation,
  useDelNormalThemesMutation,
  usePublishNormalThemesSubscription,
  usePublishUpNormalThemesSubscription,
  usePublishDelNormalThemesSubscription,
} from "@/graphql/graphql";
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import deepcopy from "deepcopy";
import equal from 'deep-equal';
import dateFormat from "dateformat";

interface State {
  themes: any[];
  currentTheme: any;
  currentNewTheme: Theme;
  searchText: string;
  newThemes: Theme[];
  updateThemes: Theme[];
  delThemeIds: number[];
  style: any;
  prevRange: any;
  showIntelliSence: boolean;
  currentChoiseIndex: number;
  syncThemes: any[];
  isUpdate: false;
  originThemes: any[];
  searchCats: number[];
}

interface Theme {
  id: number;
  theme: string;
  active?: boolean;
  author?: any[];
  updatedAt?: Date;
  errors?: number[];
}

export default defineComponent({
  setup() {
    const state = reactive<State>({
      themes: [],
      currentTheme: {},
      currentNewTheme: {
        id: 0,
        theme: "",
      },
      searchText: "",
      newThemes: [],
      updateThemes: [],
      delThemeIds: [],
      style: {},
      prevRange: {},
      showIntelliSence: false,
      currentChoiseIndex: 0,
      syncThemes: [],
      isUpdate: false,
      originThemes: [],
      searchCats: []
    });
    const list = ref();
    const intelliSense = ref();

    const store = useStore();
    const themesQuery = useThemesQuery();
    const themesLoading = themesQuery.loading;
    themesQuery.onResult((res) => {
      state.themes = deepcopy(res.data.themes);
      updateOrigin(state.themes)
    });
    const user = computed(() => {
      return store.getters["auth/getUser"];
    });
    const toggleActive = (theme:any) => {
      setTimeout(() => {
        state.currentTheme.active = !state.currentTheme.active;
        updateCheck();
      }, 0);
    };
    const format = (date: string) => {
      return dateFormat(date, "yyyy/mm/dd HH:MM");
    };
    const addTheme = async () => {
      state.newThemes.unshift({
        id: state.newThemes.length,
        theme: "",
      });
      await nextTick();
      list.value.children[0].children[0].focus();
    };
    const delTheme = () => {
      setTimeout(() => {
        const index = state.delThemeIds.indexOf(Number(state.currentTheme.id));
        if (index == -1) {
          state.delThemeIds.push(Number(state.currentTheme.id));
        } else {
          state.delThemeIds.splice(index, 1);
        }
      }, 0);
    };
    // 
    watch(state.updateThemes,(n)=>{
      for(const theme of state.updateThemes) {
        const target = state.originThemes.find(x=>x.id==theme.id);
        if(typeof theme.errors != 'undefined') {
          target.errors = theme.errors
        }
      }
    })
    // 絞り込み
    const filterThemes = computed(() => {
      const copy = state.themes.slice()
      const texts = state.searchText.split(/\s/);
      const res = copy.filter((theme:any)=>{
        let check = false;
        for( const text of texts ) {
          check = theme.theme.includes(text);
        }
        if(state.searchCats.includes(-1)){
          check = theme.author.uuid == user.value.uuid
        }
        // 有効
        if(state.searchCats.includes(-2)){
          check = theme.active == true;
        }
        // 無効
        if(state.searchCats.includes(-3)){
          check = theme.active == false;
        }
        return check
      });
      
      return res;
    });
    const removeNewTheme = () => {
      setTimeout(() => {
        const index = state.newThemes.findIndex(
          (x) => x.id == state.currentNewTheme.id
        );
        if (index != -1) {
          state.newThemes.splice(index, 1);
        }
      }, 0);
    };
    const getCaretGlobalPosition = () => {
      const sel = document.getSelection();
      if (sel && sel.rangeCount > 0) {
        const range = sel.getRangeAt(0);
        if (!range) return;
        const node = range.startContainer;
        const offset = range.startOffset;
        state.prevRange = {
          node: node,
          offset: offset,
        };
        const pageOffset = { x: window.pageXOffset, y: window.pageYOffset };
        let rect, r2;
        if (offset > 0) {
          r2 = document.createRange();
          r2.setStart(node, offset - 1);
          r2.setEnd(node, offset);
          rect = r2.getBoundingClientRect();
          return {
            left: rect.right + pageOffset.x + "px",
            top: rect.bottom + pageOffset.y + "px",
          };
        }
      }
    };
    const input = (e:any,theme:any) => {
      theme.theme = e.target.innerText;
      if (!e.isComposing) {
        if (e.data == "@") {
          state.showIntelliSence = true;
          state.style = getCaretGlobalPosition();
        }
      }
    }
    const updateInput = async (e: any, theme: Theme) => {
      state.syncThemes[theme.id] = e.target.innerText;
      if (!e.isComposing) {
        if (e.data == "@") {
          state.showIntelliSence = true;
          state.style = getCaretGlobalPosition();
        }
      }
      updateCheck()
    };

    const categories = computed(()=>{
      return store.getters["db/getCategories"];
    }) 

    const choice = async (text: string) => {
      const sel = document.getSelection();
      if (sel) {
        const range = document.createRange();
        range.setStart(state.prevRange.node, state.prevRange.offset);
        range.setEnd(state.prevRange.node, state.prevRange.offset);
        const reg = new RegExp("@" + text, "g");
        const match:any = document.activeElement?.textContent?.match(reg);
        const num = match ? match.length + 1 : 1;
        const catText = document.createTextNode(text + num);
        range.insertNode(catText);
        range.setStart(catText, catText.length);
        range.setEnd(catText, catText.length);
        sel.removeAllRanges();
        sel.addRange(range);
        state.showIntelliSence = false;
        const theme = document.activeElement?.textContent;
        if(state.isUpdate) state.syncThemes[state.currentTheme.id] = theme;
        updateCheck()
      }
    };
    // インテリセンスのキー操作
    const keydown = (e: any) => {
      // エンター
      if (state.showIntelliSence) {
        if (e.keyCode == 13) {
          e.preventDefault();
          choice( intelliSense.value.children[state.currentChoiseIndex].innerText );
        } else if (e.keyCode == 38) {
          e.preventDefault();
          if (state.currentChoiseIndex > 0) {
            state.currentChoiseIndex--;
            if (state.currentChoiseIndex < categories.value.length - 5) {
              intelliSense.value.scrollTop = intelliSense.value.scrollTop - 25;
            }
          }
        } else if (e.keyCode == 40) {
          e.preventDefault();
          if (state.currentChoiseIndex < categories.value.length - 1) {
            state.currentChoiseIndex++;
          }
          if (state.currentChoiseIndex > 4) {
            intelliSense.value.scrollTop = intelliSense.value.scrollTop + 25;
          }
        } else {
          state.showIntelliSence = false
        }
      }
    };
    const check = (theme: any) => {
      const match = theme.theme.match(/＿＿/g);
      let check = false;
      let errors = []
      if(match) {
        const aIndex = theme.theme.indexOf('＿＿');
        const qIndex = theme.theme.indexOf('＠＠');
        const trimTheme = trim(theme.theme);
        if(trimTheme=="＿＿"){
          store.dispatch('toast/setNotice',{
            class: "red",
            message: "穴埋め以外にも文章を入れてください。"
          })
          errors.push(0)
          check = true
        }
        // ＠＠が前に存在する場合
        if( qIndex != -1 && aIndex != -1 && qIndex < aIndex ) {
          store.dispatch('toast/setNotice',{
            class: "red",
            message: "穴埋めより前に繰り返しが入っています。"
          })
          errors.push(1)
          check = true
        }
        // 穴埋め
        if(match.length >= 3) {
          store.dispatch('toast/setNotice',{
            class: "red",
            message: "穴埋めは最大で2個までです。"
          })
          errors.push(2)
          check = true
        }
      }else{
        store.dispatch('toast/setNotice',{
          class: "red",
          message: "最低1個は穴埋めを入れてください。"
        })
        errors.push(3)
        check = true
      }
      theme.errors = errors
      return check;
    };

    const addNormalThemesMutation = useAddNormalThemesMutation({});
    const updateNormalThemesMutation = useUpdateNormalThemesMutation({});
    const delNormalThemesMutation = useDelNormalThemesMutation({});

    const saveLoading = updateNormalThemesMutation.loading || addNormalThemesMutation.loading || delNormalThemesMutation.loading;
    const save = async () => {
      // エラーチェック
      const updateData = []
      let errors = [];
      for(const theme of state.updateThemes) {
        errors.push(check(theme))
        updateData.push( {
          id: theme.id,
          theme: theme.theme,
          active: theme.active,
          author: theme.author
        })
      }
      const newData = []
      for(const theme of state.newThemes) {
        errors.push(check(theme))
        newData.push( {
          id: theme.id,
          theme: theme.theme,
          active: theme.active,
          author: theme.author
        })
      }
      // エラーがあったら処理しない
      if( errors.some(x=>x) ) return;
      // 更新と削除両方の場合は削除を優先
      for(const id of state.delThemeIds){
        const index = state.updateThemes.findIndex(x=>x.id==id);
        if( index != -1 ) state.updateThemes.splice(index,1)
      }
      if(state.updateThemes.length) {
        await updateNormalThemesMutation.mutate({ data: updateData })
        state.updateThemes = [];
      }
      if(state.newThemes.length) {
        await addNormalThemesMutation.mutate({ data: newData })
        state.newThemes = [];
      }
      if(state.delThemeIds.length) {
        await delNormalThemesMutation.mutate({
          data: state.delThemeIds
        })
        state.delThemeIds = []
      }
    };


    // 既存のお題を編集したとき
    const updateCheck = () => {
      // 現在のデータ
      const theme = deepcopy(state.currentTheme);
      if(state.syncThemes[theme.id]) theme.theme = state.syncThemes[theme.id];
      // 既にアプデに登録されている場合は取得
      const current:any = state.updateThemes.find( x => x.id == theme.id );
      // 元のデータ
      const prev = state.originThemes.find(x=>x.id==theme.id);
      // 元のデータと比較して同じなら更新から外す
      if( theme && prev ) {
        if(equal(theme,prev)){
          if(current){
            state.updateThemes.splice(state.updateThemes.indexOf(current),1)
          }
        }else{
          // データが異なっていて更新にまだ入っていなければ登録 
          if( !state.updateThemes.find( x => x.id == theme.id ) ) {
            const data = {
              id: Number(theme.id),
              theme: theme.theme,
              active: theme.active
            }
            state.updateThemes.push(data);
          }else{
            const data = {
              id: Number(theme.id),
              theme: theme.theme,
              active: theme.active
            }
            state.updateThemes.splice(state.updateThemes.indexOf(current),1,data);
          }
        }
      }
    }
    const trim = (word:string) => {
      return word.replace(/\s+/g,'');
    }

    const normalThemesSubscription = usePublishNormalThemesSubscription()
    const normalUpThemesSubscription = usePublishUpNormalThemesSubscription()
    const normalDelThemesSubscription = usePublishDelNormalThemesSubscription()

    normalThemesSubscription.onResult((res)=>{
      const themes = res.data!.publishNormalThemes;
      state.themes = [...themes,...state.themes]      
      updateOrigin(state.themes)
    })
    normalUpThemesSubscription.onResult((res)=>{
      const themes = res.data!.publishUpNormalThemes;
      for(const theme of themes){
        state.themes.splice(state.themes.findIndex(x=>x.id==theme.id),1,theme)
      }
      updateOrigin(state.themes)
    })
    normalDelThemesSubscription.onResult((res)=>{
      const ids = res.data!.publishDelNormalThemes;
      for(const id of ids){
        state.themes.splice(state.themes.findIndex(x=>x.id==id),1)
      }
      updateOrigin(state.themes)
    })
    const updateOrigin = (themes:any[]) => {
      state.originThemes = deepcopy(themes)
    }

    const search = (id:any) => {
      const index = state.searchCats.indexOf(id);
      if(index == -1) {
        state.searchCats.push(id);
      } else {
        state.searchCats.splice(index,1);
      }
    }

    return {
      state,
      user,
      themesLoading,
      list,
      intelliSense,
      saveLoading,
      filterThemes,
      categories,
      save,
      toggleActive,
      format,
      addTheme,
      delTheme,
      removeNewTheme,
      getCaretGlobalPosition,
      focus,
      input,
      updateInput,
      choice,
      keydown,
      check,
      search
    };
  },
});
