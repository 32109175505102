export const auth = {
  namespaced: true,
  state: {
    user: [],
    isAuth: false
  },
  getters: {
    getUser( state: any ){
      return state.user
    },
    isAuth( state: any ){
      return state.isAuth
    }
  },
  mutations: {
    setUser( state: any, payload: any ) {
      state.user = payload
      state.isAuth = !!payload
    }
  },
  actions: {
    setUser( { commit }: any , payload: any ) {
      commit( 'setUser', payload );
    }
  }
}