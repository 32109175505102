import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NotFound from '@/components/NotFound.vue'
import Login from '@/views/Login.vue'
import BotIndex from '@/views/bot/Index.vue';
import Dashboard from '@/views/bot/Dashboard.vue'
import Word from '@/views/bot/Word.vue'
import Test from '@/views/bot/Test.vue'
import Theme from '@/views/bot/Theme.vue'
import Setting from '@/views/bot/Setting.vue'
import Room from '@/views/bot/Room.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: NotFound
  },
  {
    path: "/login/:uid/:uuid",
    component: Login
  },
  {
    path: "/bot",
    name: "bot",
    component: BotIndex,
    children: [
      {
        path: "",
        component: Dashboard
      },
      {
        path: "word",
        component: Word
      },
      {
        path: "theme",
        component: Theme
      },
      {
        path: "setting",
        component: Setting
      },
      {
        path: "test",
        component: Test
      },
      {
        path: "room",
        component: Room
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // const token = localStorage.getItem('token');
  // if(/bot/.test(to.path)){
  //   if(token) {
  //     next();
  //   }else{
  //     next("/");
  //   }
  // }else {
  //   next();
  // }
  next();
})

export default router
