export const db = {
  namespaced: true,
  state: {
    words: [],
    originWords: [],
    themes: [],
    originThemes: [],
    categories: [],
    originCategories: []
  },
  getters: {
    getWords( state: any ){
      return state.words
    },
    getOriginWords( state: any ){
      return state.originWords
    },
    getThemes( state: any ){
      return state.themes
    },
    getCategories(state:any){
      return state.categories
    }
  },
  mutations: {
    setWords( state: any, payload: any ) {
      state.words = payload
    },
    setOriginWords( state: any, payload: any ) {
      state.originWords = payload
    },
    setThemes( state: any, payload: any ) {
      state.themes = payload
    },
    setCategories( state: any, payload: any ) {
      state.categories = payload
    }
  },
  actions: {
    setWords( { commit }: any, payload: any ) {
      commit( 'setWords', payload );
    },
    setOriginWords( { commit }: any, payload: any ) {
      commit( 'setOriginWords', payload );
    },
    setThemes( { commit }: any , payload: any ) {
      commit( 'setThemes', payload );
    },
    setCategories( { commit }: any , payload: any ) {
      commit( 'setCategories', payload );
    }
  }
}