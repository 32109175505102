import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
};

export type Item = {
  __typename?: 'Item';
  author: User;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  item: Scalars['String'];
  type: ItemType;
};

export type ItemType = {
  __typename?: 'ItemType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addNormalThemes: Array<NormalTheme>;
  addWord: Array<Word>;
  addWordCategory: WordCategory;
  createItem: Item;
  delItem: Scalars['String'];
  delNormalThemes: Array<Scalars['Float']>;
  delWords: Array<Scalars['Float']>;
  logout: Scalars['Boolean'];
  pubSubMutation: Scalars['Boolean'];
  pubSubMutationToDynamicTopic: Scalars['Boolean'];
  publisherMutation: Scalars['Boolean'];
  tempLogin: Scalars['String'];
  updateNormalThemes: Array<NormalTheme>;
  updateWords: Array<Word>;
};


export type MutationAddNormalThemesArgs = {
  data: Array<NormalThemeInput>;
};


export type MutationAddWordArgs = {
  words: Array<WordInput>;
};


export type MutationAddWordCategoryArgs = {
  wordCategoryNameInput: WordCategoryNameInput;
};


export type MutationCreateItemArgs = {
  typeId: Scalars['Float'];
};


export type MutationDelItemArgs = {
  ids: Array<Scalars['Float']>;
};


export type MutationDelNormalThemesArgs = {
  data: Array<Scalars['Float']>;
};


export type MutationDelWordsArgs = {
  data: Array<Scalars['Float']>;
};


export type MutationPubSubMutationArgs = {
  message?: InputMaybe<Scalars['String']>;
};


export type MutationPubSubMutationToDynamicTopicArgs = {
  message?: InputMaybe<Scalars['String']>;
  topic: Scalars['String'];
};


export type MutationPublisherMutationArgs = {
  message?: InputMaybe<Scalars['String']>;
};


export type MutationTempLoginArgs = {
  tempId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateNormalThemesArgs = {
  data: Array<NormalThemeInput>;
};


export type MutationUpdateWordsArgs = {
  data: Array<WordInput>;
};

export type NormalTheme = {
  __typename?: 'NormalTheme';
  active: Scalars['Boolean'];
  author: User;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  theme: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NormalThemeInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  theme: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  date: Scalars['Timestamp'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  currentDate: Scalars['Timestamp'];
  me: User;
  themes: Array<NormalTheme>;
  wordCategories: Array<WordCategory>;
  words: Array<Word>;
};

export type Subscription = {
  __typename?: 'Subscription';
  normalSubscription: Notification;
  publishDelNormalThemes: Array<Scalars['Float']>;
  publishDelWords: Array<Scalars['Float']>;
  publishNormalThemes: Array<NormalTheme>;
  publishUpNormalThemes: Array<NormalTheme>;
  publishUpWords: Array<Word>;
  publishWords: Array<Word>;
  subscriptionWithFilter: Notification;
  subscriptionWithFilterToDynamicTopic: Notification;
  updateCategory: WordCategory;
};


export type SubscriptionSubscriptionWithFilterToDynamicTopicArgs = {
  topic: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  items: Array<Item>;
  nickname?: Maybe<Scalars['String']>;
  themes: Array<NormalTheme>;
  uuid: Scalars['ID'];
  wordCategorys: Array<WordCategory>;
  words: Array<Word>;
};

export type Word = {
  __typename?: 'Word';
  active: Scalars['Boolean'];
  author: User;
  categories: Array<WordCategory>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mods?: Maybe<Array<WordMod>>;
  updatedAt: Scalars['DateTime'];
  word: Scalars['String'];
};

export type WordCategory = {
  __typename?: 'WordCategory';
  author: User;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  words: Array<Word>;
};

export type WordCategoryNameInput = {
  name: Scalars['String'];
};

export type WordInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  categories: Array<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  mods?: InputMaybe<Array<WordModInput>>;
  word: Scalars['String'];
};

export type WordMod = {
  __typename?: 'WordMod';
  id: Scalars['ID'];
  mod: Scalars['String'];
  word: Word;
};

export type WordModInput = {
  id?: InputMaybe<Scalars['ID']>;
  mod: Scalars['String'];
};

export type AddNormalThemesMutationVariables = Exact<{
  data: Array<NormalThemeInput> | NormalThemeInput;
}>;


export type AddNormalThemesMutation = { __typename?: 'Mutation', addNormalThemes: Array<{ __typename?: 'NormalTheme', id: string, theme: string, active: boolean, updatedAt: any, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } }> };

export type AddWordMutationVariables = Exact<{
  words: Array<WordInput> | WordInput;
}>;


export type AddWordMutation = { __typename?: 'Mutation', addWord: Array<{ __typename?: 'Word', id: string, word: string, categories: Array<{ __typename?: 'WordCategory', id: string }> }> };

export type AddWordCategoryMutationVariables = Exact<{
  wordCategoryNameInput: WordCategoryNameInput;
}>;


export type AddWordCategoryMutation = { __typename?: 'Mutation', addWordCategory: { __typename?: 'WordCategory', name: string } };

export type CreateItemMutationVariables = Exact<{
  typeId: Scalars['Float'];
}>;


export type CreateItemMutation = { __typename?: 'Mutation', createItem: { __typename?: 'Item', id: string, item: string, createdAt: any, type: { __typename?: 'ItemType', type: string }, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } } };

export type DelItemMutationVariables = Exact<{
  ids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type DelItemMutation = { __typename?: 'Mutation', delItem: string };

export type DelNormalThemesMutationVariables = Exact<{
  data: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type DelNormalThemesMutation = { __typename?: 'Mutation', delNormalThemes: Array<number> };

export type DelWordsMutationVariables = Exact<{
  data: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type DelWordsMutation = { __typename?: 'Mutation', delWords: Array<number> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type TempLoginMutationVariables = Exact<{
  userId: Scalars['String'];
  tempId: Scalars['String'];
}>;


export type TempLoginMutation = { __typename?: 'Mutation', tempLogin: string };

export type UpdateNormalThemesMutationVariables = Exact<{
  data: Array<NormalThemeInput> | NormalThemeInput;
}>;


export type UpdateNormalThemesMutation = { __typename?: 'Mutation', updateNormalThemes: Array<{ __typename?: 'NormalTheme', id: string, theme: string, active: boolean, updatedAt: any, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } }> };

export type UpdateWordsMutationVariables = Exact<{
  data: Array<WordInput> | WordInput;
}>;


export type UpdateWordsMutation = { __typename?: 'Mutation', updateWords: Array<{ __typename?: 'Word', id: string, word: string, active: boolean, updatedAt: any, mods?: Array<{ __typename?: 'WordMod', id: string, mod: string }> | null | undefined, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined }, categories: Array<{ __typename?: 'WordCategory', id: string, name: string }> }> };

export type DateQueryVariables = Exact<{ [key: string]: never; }>;


export type DateQuery = { __typename?: 'Query', currentDate: any };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', uuid: string, email: string, nickname?: string | null | undefined, items: Array<{ __typename?: 'Item', id: string, item: string, createdAt: any, type: { __typename?: 'ItemType', type: string } }> } };

export type ThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemesQuery = { __typename?: 'Query', themes: Array<{ __typename?: 'NormalTheme', id: string, theme: string, active: boolean, updatedAt: any, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } }> };

export type WordCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type WordCategoriesQuery = { __typename?: 'Query', wordCategories: Array<{ __typename?: 'WordCategory', id: string, name: string, updatedAt: any, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } }> };

export type WordsQueryVariables = Exact<{ [key: string]: never; }>;


export type WordsQuery = { __typename?: 'Query', words: Array<{ __typename?: 'Word', id: string, word: string, active: boolean, updatedAt: any, mods?: Array<{ __typename?: 'WordMod', id: string, mod: string }> | null | undefined, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined }, categories: Array<{ __typename?: 'WordCategory', id: string, name: string }> }> };

export type NormalSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NormalSubscription = { __typename?: 'Subscription', normalSubscription: { __typename?: 'Notification', id: string, message?: string | null | undefined, date: any } };

export type PublishDelNormalThemesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishDelNormalThemesSubscription = { __typename?: 'Subscription', publishDelNormalThemes: Array<number> };

export type PublishDelWordsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishDelWordsSubscription = { __typename?: 'Subscription', publishDelWords: Array<number> };

export type PublishNormalThemesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishNormalThemesSubscription = { __typename?: 'Subscription', publishNormalThemes: Array<{ __typename?: 'NormalTheme', id: string, theme: string, active: boolean, updatedAt: any, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } }> };

export type PublishUpNormalThemesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishUpNormalThemesSubscription = { __typename?: 'Subscription', publishUpNormalThemes: Array<{ __typename?: 'NormalTheme', id: string, theme: string, active: boolean, updatedAt: any, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } }> };

export type PublishUpWordsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishUpWordsSubscription = { __typename?: 'Subscription', publishUpWords: Array<{ __typename?: 'Word', id: string, word: string, active: boolean, updatedAt: any, mods?: Array<{ __typename?: 'WordMod', id: string, mod: string }> | null | undefined, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined }, categories: Array<{ __typename?: 'WordCategory', id: string, name: string }> }> };

export type PublishWordsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishWordsSubscription = { __typename?: 'Subscription', publishWords: Array<{ __typename?: 'Word', id: string, word: string, active: boolean, updatedAt: any, mods?: Array<{ __typename?: 'WordMod', id: string, mod: string }> | null | undefined, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined }, categories: Array<{ __typename?: 'WordCategory', id: string, name: string }> }> };

export type UpdateCategorySubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UpdateCategorySubscription = { __typename?: 'Subscription', updateCategory: { __typename?: 'WordCategory', id: string, name: string, updatedAt: any, author: { __typename?: 'User', uuid: string, nickname?: string | null | undefined } } };


export const AddNormalThemesDocument = gql`
    mutation addNormalThemes($data: [NormalThemeInput!]!) {
  addNormalThemes(data: $data) {
    id
    theme
    active
    author {
      uuid
      nickname
    }
    updatedAt
  }
}
    `;

/**
 * __useAddNormalThemesMutation__
 *
 * To run a mutation, you first call `useAddNormalThemesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddNormalThemesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddNormalThemesMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useAddNormalThemesMutation(options: VueApolloComposable.UseMutationOptions<AddNormalThemesMutation, AddNormalThemesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddNormalThemesMutation, AddNormalThemesMutationVariables>>) {
  return VueApolloComposable.useMutation<AddNormalThemesMutation, AddNormalThemesMutationVariables>(AddNormalThemesDocument, options);
}
export type AddNormalThemesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddNormalThemesMutation, AddNormalThemesMutationVariables>;
export const AddWordDocument = gql`
    mutation addWord($words: [WordInput!]!) {
  addWord(words: $words) {
    id
    word
    categories {
      id
    }
  }
}
    `;

/**
 * __useAddWordMutation__
 *
 * To run a mutation, you first call `useAddWordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddWordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddWordMutation({
 *   variables: {
 *     words: // value for 'words'
 *   },
 * });
 */
export function useAddWordMutation(options: VueApolloComposable.UseMutationOptions<AddWordMutation, AddWordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddWordMutation, AddWordMutationVariables>>) {
  return VueApolloComposable.useMutation<AddWordMutation, AddWordMutationVariables>(AddWordDocument, options);
}
export type AddWordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddWordMutation, AddWordMutationVariables>;
export const AddWordCategoryDocument = gql`
    mutation addWordCategory($wordCategoryNameInput: WordCategoryNameInput!) {
  addWordCategory(wordCategoryNameInput: $wordCategoryNameInput) {
    name
  }
}
    `;

/**
 * __useAddWordCategoryMutation__
 *
 * To run a mutation, you first call `useAddWordCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddWordCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddWordCategoryMutation({
 *   variables: {
 *     wordCategoryNameInput: // value for 'wordCategoryNameInput'
 *   },
 * });
 */
export function useAddWordCategoryMutation(options: VueApolloComposable.UseMutationOptions<AddWordCategoryMutation, AddWordCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddWordCategoryMutation, AddWordCategoryMutationVariables>>) {
  return VueApolloComposable.useMutation<AddWordCategoryMutation, AddWordCategoryMutationVariables>(AddWordCategoryDocument, options);
}
export type AddWordCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddWordCategoryMutation, AddWordCategoryMutationVariables>;
export const CreateItemDocument = gql`
    mutation CreateItem($typeId: Float!) {
  createItem(typeId: $typeId) {
    id
    item
    type {
      type
    }
    createdAt
    author {
      uuid
      nickname
    }
  }
}
    `;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateItemMutation({
 *   variables: {
 *     typeId: // value for 'typeId'
 *   },
 * });
 */
export function useCreateItemMutation(options: VueApolloComposable.UseMutationOptions<CreateItemMutation, CreateItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateItemMutation, CreateItemMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
}
export type CreateItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateItemMutation, CreateItemMutationVariables>;
export const DelItemDocument = gql`
    mutation delItem($ids: [Float!]!) {
  delItem(ids: $ids)
}
    `;

/**
 * __useDelItemMutation__
 *
 * To run a mutation, you first call `useDelItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDelItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDelItemMutation({
 *   variables: {
 *     ids: // value for 'ids'
 *   },
 * });
 */
export function useDelItemMutation(options: VueApolloComposable.UseMutationOptions<DelItemMutation, DelItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DelItemMutation, DelItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DelItemMutation, DelItemMutationVariables>(DelItemDocument, options);
}
export type DelItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DelItemMutation, DelItemMutationVariables>;
export const DelNormalThemesDocument = gql`
    mutation delNormalThemes($data: [Float!]!) {
  delNormalThemes(data: $data)
}
    `;

/**
 * __useDelNormalThemesMutation__
 *
 * To run a mutation, you first call `useDelNormalThemesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDelNormalThemesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDelNormalThemesMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useDelNormalThemesMutation(options: VueApolloComposable.UseMutationOptions<DelNormalThemesMutation, DelNormalThemesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DelNormalThemesMutation, DelNormalThemesMutationVariables>>) {
  return VueApolloComposable.useMutation<DelNormalThemesMutation, DelNormalThemesMutationVariables>(DelNormalThemesDocument, options);
}
export type DelNormalThemesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DelNormalThemesMutation, DelNormalThemesMutationVariables>;
export const DelWordsDocument = gql`
    mutation delWords($data: [Float!]!) {
  delWords(data: $data)
}
    `;

/**
 * __useDelWordsMutation__
 *
 * To run a mutation, you first call `useDelWordsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDelWordsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDelWordsMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useDelWordsMutation(options: VueApolloComposable.UseMutationOptions<DelWordsMutation, DelWordsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DelWordsMutation, DelWordsMutationVariables>>) {
  return VueApolloComposable.useMutation<DelWordsMutation, DelWordsMutationVariables>(DelWordsDocument, options);
}
export type DelWordsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DelWordsMutation, DelWordsMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLogoutMutation();
 */
export function useLogoutMutation(options: VueApolloComposable.UseMutationOptions<LogoutMutation, LogoutMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LogoutMutation, LogoutMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LogoutMutation, LogoutMutationVariables>;
export const TempLoginDocument = gql`
    mutation tempLogin($userId: String!, $tempId: String!) {
  tempLogin(userId: $userId, tempId: $tempId)
}
    `;

/**
 * __useTempLoginMutation__
 *
 * To run a mutation, you first call `useTempLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useTempLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useTempLoginMutation({
 *   variables: {
 *     userId: // value for 'userId'
 *     tempId: // value for 'tempId'
 *   },
 * });
 */
export function useTempLoginMutation(options: VueApolloComposable.UseMutationOptions<TempLoginMutation, TempLoginMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<TempLoginMutation, TempLoginMutationVariables>>) {
  return VueApolloComposable.useMutation<TempLoginMutation, TempLoginMutationVariables>(TempLoginDocument, options);
}
export type TempLoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<TempLoginMutation, TempLoginMutationVariables>;
export const UpdateNormalThemesDocument = gql`
    mutation updateNormalThemes($data: [NormalThemeInput!]!) {
  updateNormalThemes(data: $data) {
    id
    theme
    active
    author {
      uuid
      nickname
    }
    updatedAt
  }
}
    `;

/**
 * __useUpdateNormalThemesMutation__
 *
 * To run a mutation, you first call `useUpdateNormalThemesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNormalThemesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateNormalThemesMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNormalThemesMutation(options: VueApolloComposable.UseMutationOptions<UpdateNormalThemesMutation, UpdateNormalThemesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateNormalThemesMutation, UpdateNormalThemesMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateNormalThemesMutation, UpdateNormalThemesMutationVariables>(UpdateNormalThemesDocument, options);
}
export type UpdateNormalThemesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateNormalThemesMutation, UpdateNormalThemesMutationVariables>;
export const UpdateWordsDocument = gql`
    mutation updateWords($data: [WordInput!]!) {
  updateWords(data: $data) {
    id
    word
    active
    mods {
      id
      mod
    }
    author {
      uuid
      nickname
    }
    categories {
      id
      name
    }
    updatedAt
  }
}
    `;

/**
 * __useUpdateWordsMutation__
 *
 * To run a mutation, you first call `useUpdateWordsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWordsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateWordsMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWordsMutation(options: VueApolloComposable.UseMutationOptions<UpdateWordsMutation, UpdateWordsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateWordsMutation, UpdateWordsMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateWordsMutation, UpdateWordsMutationVariables>(UpdateWordsDocument, options);
}
export type UpdateWordsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateWordsMutation, UpdateWordsMutationVariables>;
export const DateDocument = gql`
    query date {
  currentDate
}
    `;

/**
 * __useDateQuery__
 *
 * To run a query within a Vue component, call `useDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDateQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDateQuery();
 */
export function useDateQuery(options: VueApolloComposable.UseQueryOptions<DateQuery, DateQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DateQuery, DateQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DateQuery, DateQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DateQuery, DateQueryVariables>(DateDocument, {}, options);
}
export type DateQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DateQuery, DateQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    uuid
    email
    nickname
    items {
      id
      item
      type {
        type
      }
      createdAt
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const ThemesDocument = gql`
    query themes {
  themes {
    id
    theme
    active
    author {
      uuid
      nickname
    }
    updatedAt
  }
}
    `;

/**
 * __useThemesQuery__
 *
 * To run a query within a Vue component, call `useThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useThemesQuery();
 */
export function useThemesQuery(options: VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ThemesQuery, ThemesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, {}, options);
}
export type ThemesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ThemesQuery, ThemesQueryVariables>;
export const WordCategoriesDocument = gql`
    query wordCategories {
  wordCategories {
    id
    name
    updatedAt
    author {
      uuid
      nickname
    }
  }
}
    `;

/**
 * __useWordCategoriesQuery__
 *
 * To run a query within a Vue component, call `useWordCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWordCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWordCategoriesQuery();
 */
export function useWordCategoriesQuery(options: VueApolloComposable.UseQueryOptions<WordCategoriesQuery, WordCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WordCategoriesQuery, WordCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WordCategoriesQuery, WordCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WordCategoriesQuery, WordCategoriesQueryVariables>(WordCategoriesDocument, {}, options);
}
export type WordCategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WordCategoriesQuery, WordCategoriesQueryVariables>;
export const WordsDocument = gql`
    query words {
  words {
    id
    word
    active
    mods {
      id
      mod
    }
    updatedAt
    author {
      uuid
      nickname
    }
    categories {
      id
      name
    }
  }
}
    `;

/**
 * __useWordsQuery__
 *
 * To run a query within a Vue component, call `useWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWordsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWordsQuery();
 */
export function useWordsQuery(options: VueApolloComposable.UseQueryOptions<WordsQuery, WordsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WordsQuery, WordsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WordsQuery, WordsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WordsQuery, WordsQueryVariables>(WordsDocument, {}, options);
}
export type WordsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WordsQuery, WordsQueryVariables>;
export const NormalDocument = gql`
    subscription normal {
  normalSubscription {
    id
    message
    date
  }
}
    `;

/**
 * __useNormalSubscription__
 *
 * To run a query within a Vue component, call `useNormalSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNormalSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useNormalSubscription();
 */
export function useNormalSubscription(options: VueApolloComposable.UseSubscriptionOptions<NormalSubscription, NormalSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<NormalSubscription, NormalSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<NormalSubscription, NormalSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<NormalSubscription, NormalSubscriptionVariables>(NormalDocument, {}, options);
}
export type NormalSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<NormalSubscription, NormalSubscriptionVariables>;
export const PublishDelNormalThemesDocument = gql`
    subscription publishDelNormalThemes {
  publishDelNormalThemes
}
    `;

/**
 * __usePublishDelNormalThemesSubscription__
 *
 * To run a query within a Vue component, call `usePublishDelNormalThemesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishDelNormalThemesSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePublishDelNormalThemesSubscription();
 */
export function usePublishDelNormalThemesSubscription(options: VueApolloComposable.UseSubscriptionOptions<PublishDelNormalThemesSubscription, PublishDelNormalThemesSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<PublishDelNormalThemesSubscription, PublishDelNormalThemesSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<PublishDelNormalThemesSubscription, PublishDelNormalThemesSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<PublishDelNormalThemesSubscription, PublishDelNormalThemesSubscriptionVariables>(PublishDelNormalThemesDocument, {}, options);
}
export type PublishDelNormalThemesSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<PublishDelNormalThemesSubscription, PublishDelNormalThemesSubscriptionVariables>;
export const PublishDelWordsDocument = gql`
    subscription publishDelWords {
  publishDelWords
}
    `;

/**
 * __usePublishDelWordsSubscription__
 *
 * To run a query within a Vue component, call `usePublishDelWordsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishDelWordsSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePublishDelWordsSubscription();
 */
export function usePublishDelWordsSubscription(options: VueApolloComposable.UseSubscriptionOptions<PublishDelWordsSubscription, PublishDelWordsSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<PublishDelWordsSubscription, PublishDelWordsSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<PublishDelWordsSubscription, PublishDelWordsSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<PublishDelWordsSubscription, PublishDelWordsSubscriptionVariables>(PublishDelWordsDocument, {}, options);
}
export type PublishDelWordsSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<PublishDelWordsSubscription, PublishDelWordsSubscriptionVariables>;
export const PublishNormalThemesDocument = gql`
    subscription publishNormalThemes {
  publishNormalThemes {
    id
    theme
    active
    author {
      uuid
      nickname
    }
    updatedAt
  }
}
    `;

/**
 * __usePublishNormalThemesSubscription__
 *
 * To run a query within a Vue component, call `usePublishNormalThemesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishNormalThemesSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePublishNormalThemesSubscription();
 */
export function usePublishNormalThemesSubscription(options: VueApolloComposable.UseSubscriptionOptions<PublishNormalThemesSubscription, PublishNormalThemesSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<PublishNormalThemesSubscription, PublishNormalThemesSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<PublishNormalThemesSubscription, PublishNormalThemesSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<PublishNormalThemesSubscription, PublishNormalThemesSubscriptionVariables>(PublishNormalThemesDocument, {}, options);
}
export type PublishNormalThemesSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<PublishNormalThemesSubscription, PublishNormalThemesSubscriptionVariables>;
export const PublishUpNormalThemesDocument = gql`
    subscription publishUpNormalThemes {
  publishUpNormalThemes {
    id
    theme
    active
    author {
      uuid
      nickname
    }
    updatedAt
  }
}
    `;

/**
 * __usePublishUpNormalThemesSubscription__
 *
 * To run a query within a Vue component, call `usePublishUpNormalThemesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishUpNormalThemesSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePublishUpNormalThemesSubscription();
 */
export function usePublishUpNormalThemesSubscription(options: VueApolloComposable.UseSubscriptionOptions<PublishUpNormalThemesSubscription, PublishUpNormalThemesSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<PublishUpNormalThemesSubscription, PublishUpNormalThemesSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<PublishUpNormalThemesSubscription, PublishUpNormalThemesSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<PublishUpNormalThemesSubscription, PublishUpNormalThemesSubscriptionVariables>(PublishUpNormalThemesDocument, {}, options);
}
export type PublishUpNormalThemesSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<PublishUpNormalThemesSubscription, PublishUpNormalThemesSubscriptionVariables>;
export const PublishUpWordsDocument = gql`
    subscription publishUpWords {
  publishUpWords {
    id
    word
    active
    mods {
      id
      mod
    }
    author {
      uuid
      nickname
    }
    categories {
      id
      name
    }
    updatedAt
  }
}
    `;

/**
 * __usePublishUpWordsSubscription__
 *
 * To run a query within a Vue component, call `usePublishUpWordsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishUpWordsSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePublishUpWordsSubscription();
 */
export function usePublishUpWordsSubscription(options: VueApolloComposable.UseSubscriptionOptions<PublishUpWordsSubscription, PublishUpWordsSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<PublishUpWordsSubscription, PublishUpWordsSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<PublishUpWordsSubscription, PublishUpWordsSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<PublishUpWordsSubscription, PublishUpWordsSubscriptionVariables>(PublishUpWordsDocument, {}, options);
}
export type PublishUpWordsSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<PublishUpWordsSubscription, PublishUpWordsSubscriptionVariables>;
export const PublishWordsDocument = gql`
    subscription publishWords {
  publishWords {
    id
    word
    active
    mods {
      id
      mod
    }
    author {
      uuid
      nickname
    }
    categories {
      id
      name
    }
    updatedAt
  }
}
    `;

/**
 * __usePublishWordsSubscription__
 *
 * To run a query within a Vue component, call `usePublishWordsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishWordsSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePublishWordsSubscription();
 */
export function usePublishWordsSubscription(options: VueApolloComposable.UseSubscriptionOptions<PublishWordsSubscription, PublishWordsSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<PublishWordsSubscription, PublishWordsSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<PublishWordsSubscription, PublishWordsSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<PublishWordsSubscription, PublishWordsSubscriptionVariables>(PublishWordsDocument, {}, options);
}
export type PublishWordsSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<PublishWordsSubscription, PublishWordsSubscriptionVariables>;
export const UpdateCategoryDocument = gql`
    subscription updateCategory {
  updateCategory {
    id
    name
    updatedAt
    author {
      uuid
      nickname
    }
  }
}
    `;

/**
 * __useUpdateCategorySubscription__
 *
 * To run a query within a Vue component, call `useUpdateCategorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategorySubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useUpdateCategorySubscription();
 */
export function useUpdateCategorySubscription(options: VueApolloComposable.UseSubscriptionOptions<UpdateCategorySubscription, UpdateCategorySubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<UpdateCategorySubscription, UpdateCategorySubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<UpdateCategorySubscription, UpdateCategorySubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<UpdateCategorySubscription, UpdateCategorySubscriptionVariables>(UpdateCategoryDocument, {}, options);
}
export type UpdateCategorySubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<UpdateCategorySubscription, UpdateCategorySubscriptionVariables>;