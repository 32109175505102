import { createStore } from 'vuex'
import { toast } from './toast'
import { auth } from './auth'
import { db } from './db'
// storeの設定
const store:any = createStore({
  modules: {
    toast,
    auth,
    db
  }
})
export default store;
