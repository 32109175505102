
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTempLoginMutation } from '@/graphql/graphql'
import { useStore } from 'vuex'

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    localStorage.removeItem('token')
    next();
  },
  setup() {
    const store = useStore();
    const show = ref<boolean>(false);
    const route = useRoute();
    const router = useRouter();
    const init = async () => {
      try {
        const { mutate, loading, error, onDone } = useTempLoginMutation({
          variables: {
            userId: route.params.uid as string,
            tempId: route.params.uuid as string
          },
        });
        const res = await mutate();
        if(res?.data?.tempLogin){
          localStorage.setItem('token', res?.data?.tempLogin);
          router.push('/bot')
        } else {
          router.push('/');
        }
      }catch(err){
        router.push('/');
      }
    }
    init();
    return {
      show
    }
  }
})
