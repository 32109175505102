import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bede99b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "list" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notices, (notice, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["notice", notice.class]),
            key: index,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.del && _ctx.del(...args)))
          }, [
            _createElementVNode("p", {
              class: "message",
              innerHTML: notice.message
            }, null, 8, _hoisted_2)
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}