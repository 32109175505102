export const toast = {
    namespaced: true,
    state: {
      notices:[]
    },
    getters: {
      getNotices (state:any) {
        return state.notices
      }
    },
    mutations: {
      setNotice (state:any,payload:any) {
        state.notices.push(payload);
      },
      delNotice (state:any) {
        if(state.notices.length!=0)state.notices.shift();
      }
    },
    actions: {
      setNotice({commit}:{commit:any},payload:any){
        commit("setNotice",payload)
        setTimeout(() => {
          commit('delNotice');
        }, 6000);
      },
      delNotice({commit}:{commit:any},payload:any){
        commit('delNotice');
      }
    }
  }
