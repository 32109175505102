
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props:{
    header: {
      type: String,
    },
    show: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close']
})
