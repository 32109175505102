
import { defineComponent, reactive, computed, onMounted, nextTick } from "@vue/runtime-core";
interface State {
  grabLine: Line|null;
  grabbing: boolean;
  zoom: number;
  startPos: Vec2;
  zoomNum: number;
  viewBox: VBox;
  baseViewBox: VBox;
  selectIds: number[];
  hoverId: string;
  clickPos: Vec2;
  resize: number;
  lines: Line[];
  base: number;
  offset: number;
  player: Player;
  stage: Panel[][];
  moveable: number[];
  grabbable: number[];
  route: Vec2[];
  mode: string;
  isMove: boolean;
  score: number[];
  viewLines: Line[];
}
interface Panel {
  id: number;
  up: number;
  down: number;
  left: number;
  right: number;
}
interface Player {
  pos: Vec2;        // 位置
  move: number;     // プレイヤーの移動回数
  shift: number;    // 壁の移動回数
  vec: number;      // プレイヤーの向き
}
interface GameState {
  turnPlayerId: string; // ターンプレイヤーのID
}
interface VBox {
  minX: number;
  minY: number;
  width: number;
  height: number;
}
interface Vec2 {
  x: number;
  y: number;
}
interface Line {
  id: number;
  p1: Vec2;
  p2: Vec2;
  isGrab?: boolean;
}
interface Rotate {
  rot: number;
  pivot: Vec2;
}

interface Distance {
  panel: Panel;
  goalDistance: number;
  check: number;
}



export default defineComponent({
  setup(prop:any,context:any) {
    const state = reactive<State>({
      grabLine: null,
      grabbing: false,
      zoom: 0,
      startPos: {
        x: 0,
        y: 0
      },
      zoomNum: 2,
      viewBox: {
        minX: 300,
        minY: 300,
        width: 300,
        height: 300
      },
      baseViewBox: {
        minX: 0,
        minY: 0,
        width: 3000,
        height: 3000
      },
      selectIds: [],
      hoverId: "",
      clickPos: {
        x: 1500,
        y: 1500
      },
      resize: 0,
      lines: [],
      base: 50,
      offset: 10,
      player: {
        pos: { x: 0, y: 5 },
        move: 1,
        shift: 1,
        vec: 4,
      },
      stage: [],
      moveable: [],
      grabbable: [],
      mode: "",
      isMove: false,
      route: [],
      score: [],
      viewLines:[],
    })
    
    onMounted(async ()=>{
      // 壁の初期化
      // state.lines = [
      //   {id: 1,p1:{x:2,y:0},p2:{x:2,y:1},isGrab:false},
      //   {id: 2,p1:{x:4,y:0},p2:{x:4,y:1},isGrab:false},
      //   {id: 3,p1:{x:2,y:1},p2:{x:2,y:2},isGrab:false},
      //   {id: 4,p1:{x:4,y:1},p2:{x:4,y:2},isGrab:false},
      //   {id: 5,p1:{x:0,y:2},p2:{x:1,y:2},isGrab:false},
      //   {id: 6,p1:{x:1,y:2},p2:{x:2,y:2},isGrab:false},
      //   {id: 7,p1:{x:2,y:2},p2:{x:3,y:2},isGrab:false},
      //   {id: 8,p1:{x:3,y:2},p2:{x:4,y:2},isGrab:false},
      //   {id: 9,p1:{x:4,y:2},p2:{x:5,y:2},isGrab:false},
      //   {id:10,p1:{x:5,y:2},p2:{x:6,y:2},isGrab:false},
      //   {id:11,p1:{x:2,y:2},p2:{x:2,y:3},isGrab:false},
      //   {id:12,p1:{x:4,y:2},p2:{x:4,y:3},isGrab:false},
      //   {id:13,p1:{x:2,y:3},p2:{x:2,y:4},isGrab:false},
      //   {id:14,p1:{x:4,y:3},p2:{x:4,y:4},isGrab:false},
      //   {id:15,p1:{x:0,y:4},p2:{x:1,y:4},isGrab:false},
      //   {id:16,p1:{x:1,y:4},p2:{x:2,y:4},isGrab:false},
      //   {id:17,p1:{x:2,y:4},p2:{x:3,y:4},isGrab:false},
      //   {id:18,p1:{x:3,y:4},p2:{x:4,y:4},isGrab:false},
      //   {id:19,p1:{x:4,y:4},p2:{x:5,y:4},isGrab:false},
      //   {id:20,p1:{x:5,y:4},p2:{x:6,y:4},isGrab:false},
      //   {id:21,p1:{x:2,y:4},p2:{x:2,y:5},isGrab:false},
      //   {id:22,p1:{x:4,y:4},p2:{x:4,y:5},isGrab:false},
      //   {id:23,p1:{x:2,y:5},p2:{x:2,y:6},isGrab:false},
      //   {id:24,p1:{x:4,y:5},p2:{x:4,y:6},isGrab:false},
      // ]
      state.lines = [
        {id: 1,p1:{x:2,y:0},p2:{x:2,y:1},isGrab:false},
        {id: 2,p1:{x:4,y:0},p2:{x:4,y:1},isGrab:false},
        {id: 3,p1:{x:2,y:1},p2:{x:2,y:2},isGrab:false},
        {id: 4,p1:{x:4,y:1},p2:{x:4,y:2},isGrab:false},
        {id: 5,p1:{x:0,y:2},p2:{x:1,y:2},isGrab:false},
        {id: 6,p1:{x:1,y:2},p2:{x:2,y:2},isGrab:false},
        {id: 7,p1:{x:2,y:2},p2:{x:3,y:2},isGrab:false},
        {id: 8,p1:{x:3,y:1},p2:{x:4,y:1},isGrab:false},
        {id: 9,p1:{x:5,y:0},p2:{x:5,y:1},isGrab:false},
        {id:10,p1:{x:5,y:1},p2:{x:5,y:2},isGrab:false},
        {id:11,p1:{x:2,y:2},p2:{x:2,y:3},isGrab:false},
        {id:12,p1:{x:4,y:2},p2:{x:4,y:3},isGrab:false},
        {id:13,p1:{x:2,y:3},p2:{x:2,y:4},isGrab:false},
        {id:14,p1:{x:4,y:3},p2:{x:4,y:4},isGrab:false},
        {id:15,p1:{x:0,y:4},p2:{x:1,y:4},isGrab:false},
        {id:16,p1:{x:1,y:4},p2:{x:2,y:4},isGrab:false},
        {id:17,p1:{x:2,y:4},p2:{x:3,y:4},isGrab:false},
        {id:18,p1:{x:3,y:4},p2:{x:4,y:4},isGrab:false},
        {id:19,p1:{x:4,y:4},p2:{x:5,y:4},isGrab:false},
        {id:20,p1:{x:5,y:4},p2:{x:6,y:4},isGrab:false},
        {id:21,p1:{x:2,y:4},p2:{x:2,y:5},isGrab:false},
        {id:22,p1:{x:4,y:4},p2:{x:4,y:5},isGrab:false},
        {id:23,p1:{x:2,y:5},p2:{x:2,y:6},isGrab:false},
        {id:24,p1:{x:4,y:5},p2:{x:4,y:6},isGrab:false},
      ]

      // ステージの初期化
      for(let i=0;i<6;i++){
        let row:Panel[] = [];
        for(let l=0;l<6;l++){
          row.push({
            id: l+6*i,
            up: i==0?-1:0,
            left: l==0?-1:0,
            right: l==5?-1:0,
            down: i==5?-1:0
          })
        }
        state.stage.push(row);
      }
      state.player.pos.x=3;
      state.player.pos.y=3;
      await updateStage();
      state.grabbable = [];
      await updateLine();
      state.moveable = await getMove(currentPanel.value);
    })

    // ステージの情報更新
    const updateStage = async () => {
      // 一旦ステージの壁をリセット
      for(let i=0;i<6;i++){
        for(let l=0;l<6;l++){
          state.stage[i][l]={
            id: l+6*i,
            up: i==0?-1:0,
            left: l==0?-1:0,
            right: l==5?-1:0,
            down: i==5?-1:0
          }
        }
      }
      // 壁の再取得
      for(const line of state.lines){
        if(line.p1.x == line.p2.x) {
          // p1のほうが上
          if( line.p1.y < line.p2.y ) {
            state.stage[line.p1.y][line.p1.x-1].right = line.id;
            state.stage[line.p1.y][line.p1.x].left = line.id;
          } else {
            state.stage[line.p1.y-1][line.p1.x-1].right = line.id;
            state.stage[line.p1.y-1][line.p1.x].left = line.id;
          }
        }
        if(line.p1.y == line.p2.y) {
          // p1のほうが上
          if(line.p1.x < line.p2.x){
            state.stage[line.p1.y-1][line.p1.x].down = line.id;
            state.stage[line.p1.y][line.p1.x].up = line.id;
          } else {
            state.stage[line.p2.y-1][line.p2.x].down = line.id;
            state.stage[line.p2.y][line.p2.x].up = line.id;
          }
        }
      }
    }

    const getPanel = async (panel:Panel,panels:number[]) => {  
      if(panel.up==0){
        const upPanel = await getPos(panel.id-6);
        if( upPanel && panels.findIndex( x => x == upPanel.id ) == -1 ) {
          panels.push(upPanel.id);
          await getPanel(upPanel,panels);
        }
      }
      if(panel.down==0){
        const downPanel = await getPos(panel.id+6);
        if( downPanel && panels.findIndex( x => x == downPanel.id ) == -1 ) {
          panels.push(downPanel.id);
          await getPanel(downPanel,panels);
        }
      }
      if(panel.left==0){
        const leftPanel = await getPos(panel.id-1);
        if( leftPanel && panels.findIndex( x => x == leftPanel.id ) == -1 ) {
          panels.push(leftPanel.id);
          await getPanel(leftPanel,panels);
        }
      }
      if(panel.right==0){
        const rightPanel = await getPos(panel.id+1);
        if( rightPanel && panels.findIndex( x => x == rightPanel.id ) == -1 ) {
          panels.push(rightPanel.id);
          await getPanel(rightPanel,panels);
        }
      }
      return panels;
    }
    const getPos = async (id:number) => {
      for(const row of state.stage){
        const panel = row.find(x=>x.id==id);
        if (panel) return panel;
      }
    }
    const getMove = async (panel:Panel) => {
      let panelIds:number[] = [panel.id]
      return await getPanel(panel,panelIds);      
    }

    const currentPanel = computed(()=>{
      return state.stage[state.player.pos.y][state.player.pos.x];
    })

    // 移動ボタンをおしたとき
    const move = async () => {
      state.moveable = await getMove(currentPanel.value);
    }

    // 掴むボタンを押したとき
    const grab = async () => {
      state.grabLine = null;
      state.grabbable = [];
      await updateLine();
    }
    const updateLine = async () => {
      const panel = state.stage[state.player.pos.y][state.player.pos.x];
      state.grabbable = []
      if(panel.up>0) state.grabbable.push(state.lines[panel.up-1].id);
      if(panel.down>0) state.grabbable.push(state.lines[panel.down-1].id);
      if(panel.left>0) state.grabbable.push(state.lines[panel.left-1].id);
      if(panel.right>0) state.grabbable.push(state.lines[panel.right-1].id);
    }
    // 回転
    const rot = (cx:number, cy:number, x:number, y:number, angle:number):number[] => {
      var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
      ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
      return [nx, ny];
    }
    // イージング
    function easeOutQuad(timeFraction: number): number {
      return 1 - (1 - timeFraction) * (1 - timeFraction);
    }
    function easeOutElastic(x: number): number {
    const c4 = (2 * Math.PI) / 3;

    return x === 0
      ? 0
      : x === 1
      ? 1
      : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
    }
    // 壁を押し開くときのアニメーション
    const rotateLine = async (duration:number,line:Line,vector:number,r:number):Promise<void> => {
      let start = performance.now();
      let copy = JSON.parse(JSON.stringify(line));
      if( state.isMove) return;
      state.isMove = true;
      return new Promise((resolve) => {
        requestAnimationFrame(function animate(time) {
          let timeFraction = (time - start) / duration;
          if( vector == 0 ) {
            const rr = rot(line.p1.x,line.p1.y,copy.p2.x,copy.p2.y,r*easeOutQuad(timeFraction));
            line.p2.x = rr[0];
            line.p2.y = rr[1];
          } else {
            const rr = rot(line.p2.x,line.p2.y,copy.p1.x,copy.p1.y,r*easeOutQuad(timeFraction));
            line.p1.x = rr[0];
            line.p1.y = rr[1];
          }
          if (timeFraction > 1) {
            timeFraction = 1;
            state.isMove = false;
            line.p1.x = Math.round(line.p1.x)
            line.p1.y = Math.round(line.p1.y)
            line.p2.x = Math.round(line.p2.x)
            line.p2.y = Math.round(line.p2.y)
            resolve();
          }
          if (timeFraction < 1) {
            requestAnimationFrame(animate);
          }
        });
      })
    }
    // 壁を押し開くとき
    const rotate = async () => {
      await rotateLine(400,state.lines[0],1,90);
      await updateStage();
    }
    // 壁をつかんで回す時
    const grabRotateLine = async (duration:number,startLine:Line,goalLine:Line,r:number):Promise<void> => {
      let start = performance.now();
      let copy = JSON.parse(JSON.stringify(startLine));
      if( state.isMove) return;
      state.isMove = true;
      return new Promise((resolve) => {
        requestAnimationFrame(function animate(time) {
          let timeFraction = (time - start) / duration;
          //
          const rr = rot(copy.p2.x,copy.p2.y,goalLine.p1.x,goalLine.p1.y,r*easeOutQuad(timeFraction));
          startLine.p1.x = rr[0];
          startLine.p1.y = rr[1];

          if (timeFraction > 1) {
            timeFraction = 1;
            state.isMove = false;

            resolve();
          }
          if (timeFraction < 1) {
            requestAnimationFrame(animate);
          }
        });
      })
    }
    // idから座標を返す
    const castPos = async (id:number):Promise<Vec2> => {
      return { x: id % 6, y: Math.floor( id / 6 ) }
    }
    // 近くのパネルのidの取得
    const getNearPanelIds = async (panel:Panel) => {
      const nearPanelIds:number[] = [panel.up,panel.down,panel.left,panel.right]
      return nearPanelIds.filter(x=>x>0)
    }

    // パネルをクリックしたとき
    const clickPanel = async (e:any,id:number) => {
      // 移動
      if( state.isMove==false && currentPanel.value.id != id && state.moveable.includes(id)) {
        // ゴール
        const goal = await castPos(id)
        const panel:Panel = state.stage[state.player.pos.y][state.player.pos.x];
        const start = await castPos(panel.id);
        const distance = {
          panel: panel,
          goalDistance: await diff(start.x,goal.x)+await diff(start.y,goal.y),
          check: 1
        };
        let distances:Distance[] = [distance]
        let score:number[] = [];
        let route:Panel[][] = [];
        score[panel.id] = 0;
        let routeArray = await getRoute(distance,distances,goal,route,score);
        const rr = routeArray.reverse().filter(x=>x.length!=0);
        // rr[0] = rr[0].filter(x=>x.id==id)
        let prevPanel = rr[0][0];
        const res = [];

        for(const ra of rr){
          if(ra.length==1){
            res.push(await castPos(ra[0].id));
            prevPanel = ra[0];
          }else{
            // 前のパネル
            const prevPos = await castPos(prevPanel.id);
            const prevNearPanelIds = await getNearPanelIds(prevPanel);
            for(const cp of ra) {
              // 現在のパネル
              const currentNearPanelIds = await getNearPanelIds(cp);
              const wall = prevNearPanelIds.concat(currentNearPanelIds).filter(function (x, i, self) {
                return self.indexOf(x) !== self.lastIndexOf(x);
              });
              const currentPos = await castPos(cp.id);
              if(wall.length==0 && await diff(prevPos.x,currentPos.x)+await diff(prevPos.y,currentPos.y)==1) {
                res.push(currentPos)
                prevPanel=cp
                break;
              }
            }
          }
        }
        const rRes = res.reverse();
        for(const pos of rRes){
          await movePlayer(200,pos);
        }
        await updateLine();
        // プレイヤーの残り移動回数を0に
        // state.player.move = 0;
      }
    }
    const moveline = async (duration:number,startLine:Line,goalLine:Line):Promise<void> => {
      if( state.isMove) return;
      const start = performance.now();
      const prevP1:Vec2 = JSON.parse(JSON.stringify(startLine.p1));
      const prevP2:Vec2 = JSON.parse(JSON.stringify(startLine.p2));
      const c1:Vec2 = {x:goalLine.p1.x-prevP1.x,y:goalLine.p1.y-prevP1.y}
      const c2:Vec2 = {x:goalLine.p2.x-prevP2.x,y:goalLine.p2.y-prevP2.y}
      state.isMove = true;
      return new Promise((resolve) => {
        requestAnimationFrame(function animate(time) {
          let timeFraction = (time - start) / duration;
          if (timeFraction > 1) {
            timeFraction = 1;
            state.isMove = false;
            resolve();
          }
          startLine.p1.x = prevP1.x + c1.x * easeOutElastic(timeFraction);
          startLine.p1.y = prevP1.y + c1.y * easeOutElastic(timeFraction);
          startLine.p2.x = prevP2.x + c2.x * easeOutElastic(timeFraction);
          startLine.p2.y = prevP2.y + c2.y * easeOutElastic(timeFraction);
          if (timeFraction < 1) {
            requestAnimationFrame(animate);
          }
        });
      })
    }
    const movePlayer = async (duration:number,pos:Vec2):Promise<void> => {
      if( state.isMove) return;
      const start = performance.now();
      const prevPos:Vec2 = JSON.parse(JSON.stringify(state.player.pos));
      const cx = pos.x-prevPos.x;
      const cy = pos.y-prevPos.y;
      if(cx==1) state.player.vec=3;
      else if(cx==-1) state.player.vec=1;
      else if(cy==1) state.player.vec=4;
      else if(cy==-1) state.player.vec=2;
      state.isMove = true;
      return new Promise((resolve) => {
        requestAnimationFrame(function animate(time) {
          let timeFraction = (time - start) / duration;
          if (timeFraction > 1) {
            timeFraction = 1;
            state.isMove = false;
            resolve();
          }
          state.player.pos.x = prevPos.x + cx * timeFraction;
          state.player.pos.y = prevPos.y + cy * timeFraction;
          if (timeFraction < 1) {
            requestAnimationFrame(animate);
          }
        });
      })
    }

    // ルートを探す
    const getRoute = async (distance:Distance,distances:Distance[],goal:Vec2,route:Panel[][],score:number[]) => {
      // 空いている方のパネルのidを取得
      let ids:number[] = [];
      if(distance.panel.up==0) ids.push(distance.panel.id-6);
      if(distance.panel.down==0) ids.push(distance.panel.id+6);
      if(distance.panel.left==0) ids.push(distance.panel.id-1);
      if(distance.panel.right==0) ids.push(distance.panel.id+1);

      for(const id of ids) {
        // パネルの取得
        const panel = await getPos(id);
        // まだ調べていないパネルなら
        if( panel && distances.findIndex( x => x.panel.id == panel.id ) == -1 ) {
          // 座標を取得
          const pos = await castPos(panel.id);
          // 距離のスコアを設定
          if(!score[panel.id]){
            score[panel.id] = score[distance.panel.id]+1;
          } else if( score[distance.panel.id]+1 < score[panel.id] ) {
            score[panel.id] = score[distance.panel.id]+1;
          }
          // データを保管
          distances.push({
            panel: panel,
            goalDistance: await diff(pos.x,goal.x)+await diff(pos.y,goal.y),
            check: 0
          });
        }
      }
      
      // 現在のパネルは確認済みにする
      distance.check = 1;

      // まだ確認していないパネルでフィルタ
      const fDist = distances.filter(x=>x.check==0);
      // ゴールとの距離だけの配列を作成
      const mDist = fDist.map(x=>x.goalDistance);
      // ゴールまでの最小値を取得
      const min = Math.min(...mDist);
      // ゴールまでの距離が一番近いパネルを取得
      let ds = fDist.find(x=>x.goalDistance==min);

      if(typeof route[score[ds!.panel.id]-1] == "undefined") route[score[ds!.panel.id]-1] = [];
      route[score[ds!.panel.id]-1].push(ds!.panel);
      // ゴールまでの距離が0のパネルが見つかるまで再帰
      if(fDist.findIndex(x=>x.goalDistance==0)==-1) {
        await getRoute(ds!,distances,goal,route,score);
      }
      state.score = score
      return route;
    }

    // 差分
    const diff = async (a:number, b:number):Promise<number> => {
      return Math.abs(a - b);
    }

    // 壁をつかむ
    const choiceLine = (line:Line) => {
      // 壁を入れる
      state.grabLine = line;
      // パネルの取得
      const panel = state.stage[state.player.pos.y][state.player.pos.x];
      // プレイヤーの向き
      if( panel.up == line.id ) state.player.vec = 2;
      if( panel.down == line.id ) state.player.vec = 4;
      if( panel.left == line.id ) state.player.vec = 1;
      if( panel.right == line.id ) state.player.vec = 3;
      // 1個前のパネルの取得
      let frontPos:Vec2 = {x:state.player.pos.x,y:state.player.pos.y};
      let offset:number[] = [];
      if( state.player.vec == 2 ) { frontPos.y-=1; offset=[1,2,3,4,5,6,7,8]; }
      else if( state.player.vec == 4 ) { frontPos.y+=1; offset=[3,4,1,2,7,8,5,6]; }
      else if( state.player.vec == 1 ) { frontPos.x-=1; offset=[2,3,4,1,6,7,8,5]; }
      else if( state.player.vec == 3 ) { frontPos.x+=1; offset=[4,3,2,1,8,5,6,7]; }
      const frontPanel = state.stage[frontPos.y][frontPos.x];

      // 移動先の表示
      state.viewLines = [];
      const pos = state.player.pos;
      // もし上が開いているなら
      if( panel.up == 0 ) state.viewLines.push({id: offset[1], p1: {x: pos.x, y: pos.y}, p2: {x: pos.x+1, y: pos.y}});
      if( panel.down == 0 ) state.viewLines.push({id: offset[3], p1: {x: pos.x, y: pos.y+1}, p2: {x: pos.x+1, y: pos.y+1}});
      if( panel.left == 0 ) state.viewLines.push({id: offset[0], p1: {x: pos.x, y: pos.y}, p2: {x: pos.x, y: pos.y+1}});
      if( panel.right == 0 ) state.viewLines.push({id: offset[2], p1: {x: pos.x+1, y: pos.y}, p2: {x: pos.x+1, y: pos.y+1}});
      
      if( frontPanel.up == 0 ) state.viewLines.push({id: offset[5], p1: {x: frontPos.x, y: frontPos.y}, p2: {x: frontPos.x+1, y: frontPos.y}});
      if( frontPanel.down == 0 ) state.viewLines.push({id: offset[7], p1: {x: frontPos.x, y: frontPos.y+1}, p2: {x: frontPos.x+1, y: frontPos.y+1}});
      if( frontPanel.left == 0 ) state.viewLines.push({id: offset[4], p1: {x: frontPos.x, y: frontPos.y}, p2: {x: frontPos.x, y: frontPos.y+1}});
      if( frontPanel.right == 0 ) state.viewLines.push({id: offset[6], p1: {x: frontPos.x+1, y: frontPos.y}, p2: {x: frontPos.x+1, y: frontPos.y+1}});
    }
    // 移動先をクリックしたとき
    const clickLine = async (line:Line) => {
      if(state.grabLine) {
        console.log(line.id);
        state.viewLines = [];
        if(line.id==1){
          state.grabLine.p1.x=line.p1.x;
          state.grabLine.p1.y=line.p1.y;
          state.grabLine.p2.x=line.p2.x;
          state.grabLine.p2.y=line.p2.y;
        }
        if(line.id==2){
          state.grabLine.p1.x=line.p1.x;
          state.grabLine.p1.y=line.p1.y;
          state.grabLine.p2.x=line.p2.x;
          state.grabLine.p2.y=line.p2.y;
        }
        if(line.id==3){
          state.grabLine.p1.x=line.p1.x;
          state.grabLine.p1.y=line.p1.y;
          state.grabLine.p2.x=line.p2.x;
          state.grabLine.p2.y=line.p2.y;
        }
        // 後ろ
        if(line.id==4){
          // await grabRotateLine(600,state.grabLine,line,90)
          state.grabLine.p1.x=line.p1.x;
          state.grabLine.p1.y=line.p1.y;
          state.grabLine.p2.x=line.p2.x;
          state.grabLine.p2.y=line.p2.y;
        }
        // 壁を押し込む
        if(line.id==6){
          await moveline(600,state.grabLine,line);
        }
        // 左に開く場合
        if(line.id==5){
          let axis = 0;
          if(state.grabLine.p1.x == line.p1.x && state.grabLine.p1.y == line.p1.y) axis = 0;
          else if(state.grabLine.p1.x == line.p2.x && state.grabLine.p1.y == line.p2.y)axis = 0;
          else axis = 1;
          await rotateLine(200,state.grabLine,axis,90);
        }
        // 右
        if(line.id==7){
          let axis = 0;
          if(state.grabLine.p1.x == line.p1.x && state.grabLine.p1.y == line.p1.y) axis = 0;
          else if(state.grabLine.p1.x == line.p2.x && state.grabLine.p1.y == line.p2.y)axis = 0;
          else axis = 1;
          await rotateLine(200,state.grabLine,axis,-90);
        } 
        // アプデ
        await updateStage();
        await updateLine();
        state.moveable = await getMove(currentPanel.value);
        // state.player.shift = 0;
        state.grabLine = null;
      }
    }
    return {
      state,
      move,
      grab,
      rotate,
      clickPanel,
      choiceLine,
      clickLine
    }
  }
});
