
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const store = useStore();
    return {
      notices: computed(() => store.getters["toast/getNotices"]),
      del: () => {
        store.dispatch('toast/delNotice')
      }
    };
  },
});
