
import {
  UpdateCategoryDocument,
  useCreateItemMutation,
  useMeQuery,
  useWordsQuery,
  useNormalSubscription,
  useWordCategoriesQuery,
  PublishWordsDocument,
} from "@/graphql/graphql";
import deepcopy from "deepcopy";
import {
  defineComponent,
  watch,
  ref,
  reactive,
  onBeforeMount,
  computed,
  onMounted,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
interface State {
  hamOpen: boolean;
  show: boolean;
  height: number;
}
export default defineComponent({
  setup() {
    const state = reactive<State>({
      hamOpen: false,
      show: false,
      height: 0,
    });
    // ユーザーを取得
    const store = useStore();
    const router = useRouter();
    const meQuery = useMeQuery();

    meQuery.onResult(async (queryResult) => {
      const me = queryResult.data?.me;
      if (me) {
        state.show = true;
        store.dispatch("auth/setUser", me);
      } else {
        state.show = false;
        router.push("/");
      }
    });

    // 単語の取得
    const wordQuery = useWordsQuery();
    const wordLoading = wordQuery.loading;
    // 単語の更新
    wordQuery.subscribeToMore({
      document: PublishWordsDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const newWords = (subscriptionData.data as any).publishWords;
        return Object.assign({}, prev, {
          words: [...newWords, ...prev.words],
        });
      },
    });
    // 単語が更新されたとき
    wordQuery.onResult((res)=>{
      store.dispatch("db/setWords",deepcopy(res.data.words));
      store.dispatch("db/setOriginWords",deepcopy(res.data.words));
    });

    // カテゴリの取得
    const wordCategories = useWordCategoriesQuery();
    const categoryLoading = wordCategories.loading;
    wordCategories.onResult((res) => {
      store.dispatch("db/setCategories", deepcopy(res.data.wordCategories));
    });

    wordCategories.subscribeToMore({
      document: UpdateCategoryDocument,
      updateQuery: (prev, { subscriptionData }) => {
        const newCategory = (subscriptionData.data as any).updateCategory;
        const res = Object.assign({}, prev, {
          wordCategories: [...prev.wordCategories, newCategory],
        });
        store.dispatch("db/setCategories", deepcopy(res));
        return res;
      },
    });

    return {
      state,
      wordLoading,
      categoryLoading
    };
  },
});
