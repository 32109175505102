
import {
  defineComponent,
  reactive,
  ref,
  nextTick,
  computed,
  onMounted
} from "vue";
import {
  useAddWordCategoryMutation,
  useWordsQuery,
  usePublishDelWordsSubscription,
  useUpdateWordsMutation,
  useDelWordsMutation,
  usePublishUpWordsSubscription,
  PublishWordsDocument
} from "@/graphql/graphql";
import { useStore } from "vuex";
import dateFormat from "dateformat";
import deepcopy from 'deepcopy';
import equal from 'deep-equal';

import WordEditor from './Word/WordEditor.vue';
import AInput from '@/components/AInput.vue';
import Modal from '@/components/Modal.vue';

interface State {
  category: string;
  key: string;
  inputIncrement: number;
  tabIndex: number;
  wordArray: any[];
  categories: any[];
  sendData: any[];
  currentWord: any;
  saveFlag: boolean;
  vh: number;
  searchCats: any[];
  editWords: any[];
  editCats: any[];
  delWords: any[];
  showModal: boolean;
  searchText: string;
}

export default defineComponent({
  components: {
    WordEditor,
    AInput,
    Modal
  },
  setup() {
    const editorRef = ref();
    const store = useStore();
    const state = reactive<State>({
      category: "",
      key: "",
      inputIncrement: 0,
      tabIndex: 0,
      wordArray: [],
      categories: [],
      sendData: [],
      currentWord: {},
      saveFlag: false,
      vh: 0,
      searchCats: [],
      editWords:[],
      editCats:[],
      delWords:[],
      showModal: false,
      searchText: ""
    });

    // 単語の取得
    const words:any = computed({
      set(val){
        store.dispatch("db/setWords",val)
      },
      get(){
        return store.getters["db/getWords"];
      }
    })
    const originWords:any = computed({
      set(val){
        store.dispatch("db/setOriginWords",val)
      },
      get(){
        return store.getters["db/getOriginWords"];
      }
    })    
    const categories = computed(()=>{
      return store.getters["db/getCategories"];
    })    

    // カテゴリの登録
    const addWordCategoryMutation = useAddWordCategoryMutation({});
    const addCategory = () => {
      addWordCategoryMutation.mutate({
        wordCategoryNameInput: {
          name: state.category
        }
      })
    }
    addWordCategoryMutation.onDone((res)=>{
      state.showModal = false
    });

    const tabClick = async(e: any) => {
      state.tabIndex = e.target.dataset.id;
      // 単語入力
      if (state.tabIndex == 1) {
        await nextTick();
        editorRef.value.focus()   
        app = document.getElementsByClassName('tab-wrap')[0];
        window.visualViewport.dispatchEvent(resize);
      }else{
        app.removeAttribute('style');
      }
    };
    let app:any = document.getElementsByClassName('tab-wrap')[0];
    const changeHeight = (e:any) => {
      if(app && state.tabIndex == 1) app.style.height = e.target.height-50+"px"
    }
    const resize = new Event('resize');
    window.visualViewport.addEventListener('resize',changeHeight);
    window.visualViewport.addEventListener('scroll',changeHeight);
    
    const filterWords = computed(()=>{
      let res = words.value.slice()
      if(state.searchText) {
        const texts = state.searchText.split(/\s/);
        res = res.filter((word:any)=>{
          let check = false;
          for( const text of texts ) {
            if(word.word.includes(text)) {
              check = true;
              break;
            }
          }
          return check
        });
      }

      if(state.searchCats.length) {
        const minus = state.searchCats.filter(x=>x<0);
        if(minus.length){
          res = res.filter((word:any)=>{
            let check = false;
            if(state.searchCats.includes(-1)){
              check = word.author.uuid == user.value.uuid
            }
            // 有効
            if(state.searchCats.includes(-2)){
              check = word.active == true;
            }
            // 無効
            if(state.searchCats.includes(-3)){
              check = word.active == false;
            }
            return check
          })
        }
        // カテゴリのチェック
        const plus = state.searchCats.filter(x=>x>=0);
        // 選択されている場合
        if(plus.length){
          res = res.filter((word:any)=>{
            let check = false;
            let count = 0;
            // ワードが持っているカテゴリと
            for(const cat of word.categories ) {
              if( plus.includes( cat.id ) ) count++;
              if( plus.length == count ) {
                check = true;
                break;
              }
            }
            return check;
          });
        }
      }
      return res;
    });
    const delWord = () => {
      const index = state.delWords.indexOf(Number(state.currentWord.id));
      if(index == -1){
        state.delWords.push(Number(state.currentWord.id))

      } else {
        state.delWords.splice(index,1);
      }
    }
    const user = computed(()=>{
      return store.getters["auth/getUser"]
    })
    const format = (date: string) => {
      return dateFormat(date, "yyyy/mm/dd HH:MM");
    }
    const pick = (categoryIndex: string) => {
      return state.currentWord.categories.some((e: any) => {
        return e.id == categoryIndex;
      });
    }
    const mouseWheel = (e: any) => {
      e.currentTarget.scrollLeft -= (80 * e.wheelDelta) / 100;
    }
    const search = (id:any) => {
      const index = state.searchCats.indexOf(id);
      if(index == -1) {
        state.searchCats.push(id);
      } else {
        state.searchCats.splice(index,1);
      }
    }
    // 有効無効の切り替え
    const toggleActive = async () => {
      state.currentWord.active=!state.currentWord.active;
      await nextTick();
      updateWordEdit()
    }
    // アップデートするワードの配列を作る
    const updateWordEdit = () => {
      // 現在のデータ
      const word = state.currentWord;
      // 登録済みのデータ
      const current = state.editWords.find( x => x.id == state.currentWord.id );
      // 元のデータ
      const prev = originWords.value.find((x:any)=>x.id==state.currentWord.id);
      // 元のデータと比較して同じなら更新から外す
      if(equal(word,prev)){
        if(current){
          state.editWords.splice(state.editWords.indexOf(current),1)
        }
      }else{
        // データが異なっていて更新にまだ入っていなければ登録
        if( !state.editWords.find( x => x.id == state.currentWord.id ) ) {
          const data = {
            id: Number(word.id),
            word: word.word,
            active: word.active,
            categories: word.categories.map((x:any)=>Number(x.id)),
            mods: word.mods
          }
          state.editWords.push(data);
        }else{
          const data = {
            id: Number(word.id),
            word: word.word,
            active: word.active,
            categories: word.categories.map((x:any)=>Number(x.id)),
            mods: word.mods
          }
          state.editWords.splice(state.editWords.indexOf(current),1,data);
        }
      }
    }
    // カテゴリのオンオフ
    const toggleCategory = (category:any) => {
      if( state.currentWord.author.uuid != user.value.uuid ) return; 
      const current = state.currentWord.categories.find((x:any)=>x.id==category.id);
      if(current){
        state.currentWord.categories.splice(state.currentWord.categories.indexOf(current),1);
      } else {
        state.currentWord.categories.push(category)
      }
      updateWordEdit()
      if( state.currentWord.categories.length == 0 ) {
        store.dispatch('toast/setNotice', {
          class: "red",
          message: "カテゴリは最低1個は設定してください。"
        });
        return;
      }
    }

    const inputWord = (e:any) => {
      if(!e.isComposing){
        const word = trim(state.currentWord.word);
        if( word.length == 0 ){
          store.dispatch('toast/setNotice',{
            class: "red",
            message: "1文字以上入力してください。"
          })
        }
        updateWordEdit()
      }
    }
    // 変更した情報の保存
    const updateMutation = useUpdateWordsMutation({});
    const delWordsMutation = useDelWordsMutation({});
    const saveLoading = updateMutation.loading || delWordsMutation.loading;
    const save = () => {
      // 更新と削除が同じidの場合は削除優先
      for(const id of state.delWords){
        const index = state.editWords.findIndex(x=>x.id==id);
        if( index != -1 ) state.editWords.splice(index,1)
      }
      // エラーチェック
      let error = false;
      for(const word of state.editWords) {
        error = word.word.length == 0 || word.categories.length == 0;
        if(error) break; 
      }
      if(error){
        store.dispatch('toast/setNotice',{
          class: "red",
          message: "入力されていない項目があります。"
        })
        return
      }
      if( state.editWords.length ) {
        updateMutation.mutate({
          data: state.editWords
        })
      }
      if( state.delWords.length ) {
        delWordsMutation.mutate({
          data: state.delWords
        })
      }
    }
    // 更新完了後
    const publishUpWordSubscription = usePublishUpWordsSubscription()
    publishUpWordSubscription.onResult(result => {
      for(const word of result.data!.publishUpWords){
        words.value.splice(words.value.findIndex((x:any)=>x.id==word.id),1,word)
      }
      store.dispatch("db/setOriginWords",deepcopy(words.value));
    })
    // 更新後に更新用データを初期化
    updateMutation.onDone(()=>{
      state.editWords = [];
    })
    // 削除を反映し
    const publishDelWordsSubscription = usePublishDelWordsSubscription();
    publishDelWordsSubscription.onResult((res)=>{
      const ids = res.data!.publishDelWords;
      for(const id of ids){
        words.value.splice(words.value.findIndex((x:any)=>x.id==id),1)
      }
      store.dispatch("db/setOriginWords",deepcopy(words.value));
    })
    // 削除後に削除用データを初期化
    delWordsMutation.onDone(async (res)=>{
      if(res.data?.delWords.indexOf(Number(state.currentWord.id)) != -1) {
        state.currentWord = {}
      }
      await nextTick();
      state.delWords = [];
    });

    const trim = (word:string) => {
      return word.replace(/\s+/g,'');
    }

    // アイテム生成用
    const addMod = () => {
      if( state.currentWord.mods.length >= 5){
        store.dispatch('toast/setNotice',{
          class: "red",
          message: "最大で5個までです。"
        })
        return
      }
      if( state.currentWord.mods.length!=0 ) {
        const lastMod = state.currentWord.mods[ state.currentWord.mods.length-1 ];
        // 最後のmodが空白じゃなければ
        if(lastMod.mod.length){
          state.currentWord.mods.push({ mod:"" })
        }
      }else{
        state.currentWord.mods=[{ mod:"" }]
      }
    }

    const modChange = (e:any) => {
      updateWordEdit();
    }
    return {
      state,         // 状態
      editorRef,     // editorのref
      filterWords,   // 絞り込み適用ワードリスト
      user,          // ユーザーデータ
      categories,
      tabClick,      // タブのクリック
      addCategory,   // カテゴリの追加
      format,        // 更新日時のフォーマット
      pick,          // 選ばれているカテゴリ
      mouseWheel,    // マウスホイールイベント
      search,        // 絞り込みイベント
      toggleActive,  // ワードの有効/無効切り替え
      delWord,
      updateWordEdit,
      toggleCategory,
      save,
      inputWord,
      trim,
      addMod,
      modChange,
      saveLoading
    };
  },
});
