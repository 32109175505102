<style lang="scss" scoped>
.setting {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	.content {
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
  }
}

</style>
<template>
	<div class="setting">
		<div class="head-wrap"><h2>ユーザー情報</h2></div>
		<div class="content">
			<dl class="dl-list-1">
				<dt>ユーザー名</dt>
				<dd>{{user.nickname}}</dd>
				<dt>メールアドレス</dt>
				<dd>{{user.email}}</dd>
			</dl>
			<h3 class="h3-main">所持アイテム</h3>
			<ul class="list-icon">
				<li v-for="item in user.items" :key="item.id">
					<span v-if="item.type.type=='ログボ'" class="material-icons">favorite</span>
					<span v-else-if="item.type.type=='優勝賞品'" class="material-icons">emoji_events</span>
					{{item.item}}
				</li>
			</ul>
			<button @click="logout">ログアウト</button>
		</div>
	</div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useLogoutMutation } from "@/graphql/graphql";

export default defineComponent({
	setup(){
		const store = useStore();
		const router = useRouter();
		const user = computed(()=>{
			return store.getters["auth/getUser"];
		})
		const logoutMutation = useLogoutMutation({});
		const logout = async () => {
			await logoutMutation.mutate();
			await store.dispatch('auth/setUser',[]);
			localStorage.removeItem('token');
			router.push('/')
		}
		return {
			user,
			logout
		}
	}
});
</script>

